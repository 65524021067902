import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import { Form, Input, Button, Image, Row, Col, Card } from 'antd';
import { useNavigate } from 'react-router-dom';


const SEND_CONFIRMATION_EMAIL = gql`
  mutation SendPedidosMoveisConfirmationEmail($email: String!) {
    sendPedidosMoveisConfirmationEmail(input: {email: $email}) {
      success
      errors
    }
  }
`;

const SendConfirmationEmail = () => {
  const [email, setEmail] = useState('');
  const [sendConfirmationEmail, { loading, error }] = useMutation(SEND_CONFIRMATION_EMAIL);
  const navigate = useNavigate();
  const handleSendEmail = async () => {
    await sendConfirmationEmail({ variables: { email } });
  };

  return (
    <Row justify="center" align="middle" style={{ height: '100vh' }}>
      <Col xs={22} sm={16} md={12} lg={8} xl={6}>
        <Card>
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            <Image src={'/logo192.png'} alt="Logo" preview={false} width={400} />
          </div>
          <h2>Reenviar E-mail de Confirmação</h2>
          <Form onFinish={handleSendEmail}>
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Por favor, preencha seu email!' }]}
            >
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Digite seu email"
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading} block>
                Enviar e-mail de confirmação
              </Button>
              
              <Button type="link" onClick={() => navigate('/register')} block>
                Não possui conta? Registre-se
              </Button>
              <Button type="link" onClick={() => navigate('/request-password-reset')} block>
                Esqueceu a senha?
              </Button>
              <Button type="link" onClick={() => navigate('/login')} block>
                Já tem conta? Login
              </Button>
            </Form.Item>
            {error && <p>Erro ao solicitar e-mail de confirmação</p>}
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default SendConfirmationEmail;