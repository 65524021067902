// src/slices/paymentProfilesSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GET_PAYMENT_PROFILES_COUNT } from '../mutations/paymentProfiles';
import client from '../apolloClient';

export const fetchPaymentProfilesCount = createAsyncThunk(
  'paymentProfiles/fetchCount',
  async (active) => {
    const { data } = await client.query({
      query: GET_PAYMENT_PROFILES_COUNT,
      variables: { active },
      fetchPolicy: 'no-cache',
    });
    return data.paymentProfilesCount;
  }
);

const paymentProfilesSlice = createSlice({
  name: 'paymentProfiles',
  initialState: {
    count: 0,
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPaymentProfilesCount.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPaymentProfilesCount.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.count = action.payload;
      })
      .addCase(fetchPaymentProfilesCount.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default paymentProfilesSlice.reducer;
