import { gql, useMutation } from '@apollo/client';

export const GET_PAYMENT_METHODS_COUNT = gql `
query paymentMethodsCount($active: Boolean) {
    paymentMethodsCount(active: $active) 
}
`;

export const SEARCH_PAYMENT_METHODS = gql`
  query SearchPaymentMethods($query: String!) {
    searchPaymentMethods(query: $query) {
      id
      name
    }
  }
`;

export const GET_PAYMENT_METHOD_BY_ID = gql`
  query GetPaymentMethodById($id: ID!) {
    paymentMethod(id: $id) {
      id
      name
      updatedAt
      createdAt
    }
  }
`;

export const GET_PAYMENT_METHODS = gql`
  query GetPaymentMethods($page: Int, $perPage: Int, $search: String) {
    paymentMethods(page: $page, perPage: $perPage, search: $search) {
      edges {
        node {
          id
          name
          active
          updatedAt
          createdAt
        }
        cursor
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`;

export const CREATE_PAYMENT_METHOD = gql`
  mutation CreatePaymentMethod($attributes: PaymentMethodAttributes!) {
    createPaymentMethod(input: { attributes: $attributes }) {
      paymentMethod {
        id
        name
        createdAt
        updatedAt
        active
      }
      errors
    }
  }
`;

export const UPDATE_PAYMENT_METHOD = gql`
  mutation UpdatePaymentMethod($id: ID!, $name: String!, $active: Boolean!) {
    updatePaymentMethod(input: { id: $id, name: $name, active: $active }) {
      paymentMethod {
        id
        name
        createdAt
        updatedAt
        active
      }
      errors
    }
  }
`;

export const UPDATE_PAYMENT_METHOD_STATUS = gql`
  mutation UpdatePaymentMethodStatus($id: ID!, $active: Boolean!) {
    updatePaymentMethodStatus(input: { id: $id, active: $active }) {
      paymentMethod {
        id
        updatedAt
        active
      }
      errors
    }
  }
`;

export const useUpdatePaymentMethodStatus = () => {
  return useMutation(UPDATE_PAYMENT_METHOD_STATUS);
};

export const useCreatePaymentMethod = () => {
  return useMutation(CREATE_PAYMENT_METHOD);
};

export const useUpdatePaymentMethod = () => {
  return useMutation(UPDATE_PAYMENT_METHOD);
};
