// src/apolloClient.js
import { ApolloClient, InMemoryCache } from '@apollo/client';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

// If you are using React Router and have a history object, import it
// import history from './history';

const apiUrl = process.env.REACT_APP_API_URL;

const uploadLink = createUploadLink({
  uri: `${apiUrl}/graphql`,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);

      if (extensions && extensions.code === 'UNAUTHENTICATED') {
        // Clear stored authentication tokens
        localStorage.removeItem('access-token');
        localStorage.removeItem('client');
        localStorage.removeItem('uid');

        // Redirect to the login page
        window.location.href = '/login';
        // Or, if you have access to the history object:
        // history.push('/login');
      }
    });
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`);

    if (networkError.statusCode === 401) {
      // Clear stored authentication tokens
      localStorage.removeItem('access-token');
      localStorage.removeItem('client');
      localStorage.removeItem('uid');

      // Redirect to the login page
      window.location.href = '/login';
      // Or, if you have access to the history object:
      // history.push('/login');
    }
  }
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('access-token');
  const client = localStorage.getItem('client');
  const uid = localStorage.getItem('uid');

  return {
    headers: {
      ...headers,
      'access-token': token || '',
      client: client || '',
      uid: uid || '',
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(errorLink).concat(uploadLink),
  cache: new InMemoryCache(),
});

export default client;
