// src/components/OrderStatusModal.js
import React from 'react';
import { Modal, Select, Input, DatePicker, Row, Col, Form, Button } from 'antd';

const { Option } = Select;

const TRANSLATE_STATUS = {
  declined: 'Rejeitado',
  cancelled: 'Cancelado',
  billed: 'Faturado',
  shipped: 'Enviado',
  completed: 'Finalizado'
};

const OrderStatusModal = ({
  visible,
  onCancel,
  onOk,
  newStatus,
  setNewStatus,
  situation,
  setSituation,
  billingDate,
  setBillingDate,
  nfNumber,
  setNfNumber
}) => {
  return (
    <Modal
      title="Alterar Status do Pedido"
      open={visible}
      onCancel={onCancel}
      onOk={onOk}
      width={600}
      footer={[
        <Button key="cancel" onClick={onCancel} danger>
          Cancelar
        </Button>,
        <Button key="submit" type="primary" onClick={onOk}>
          Atualizar
        </Button>,
      ]}
    >
      <Form layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Status">
              <Select
                value={newStatus}
                onChange={(value) => setNewStatus(value)}
                style={{ width: '100%' }}
              >
                {Object.entries(TRANSLATE_STATUS).map(([key, value]) => (
                  <Option key={key} value={key}>{value}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Situação">
              <Input
                placeholder="Situação"
                value={situation}
                onChange={(e) => setSituation(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        {newStatus === 'billed' && (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Data de Faturamento">
                <DatePicker
                  placeholder="Data de Faturamento"
                  onChange={setBillingDate}
                  format="DD/MM/YYYY HH:mm"
                  showTime={{ format: 'HH:mm' }}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Nota Fiscal">
                <Input
                  placeholder="Número da Nota Fiscal"
                  value={nfNumber}
                  onChange={(e) => setNfNumber(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </Modal>
  );
};

export default OrderStatusModal;
