import React, { useEffect } from 'react';
import { Form, Input, Button, Alert, Switch } from 'antd';

const PaymentMethodForm = ({ initialValues, isEditMode, onFinish, onCancel, errorMessage }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [initialValues, form]);

  return (
    <Form
      form={form}
      initialValues={initialValues}
      onFinish={onFinish}
      layout="vertical"
    >
      {errorMessage && <Alert message={errorMessage} type="error" showIcon />}
      <Form.Item name="name" label="Nome" rules={[{ required: true, message: 'Por favor insira o nome' }]}>
        <Input />
      </Form.Item>
      {isEditMode && (
        <Form.Item name="active" label="Ativo" valuePropName="checked">
          <Switch />
        </Form.Item>
      )}
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={onCancel} style={{ marginRight: 8, backgroundColor: 'red', color: 'white' }}>
          Cancelar
        </Button>
        <Button type="primary" htmlType="submit">
          {isEditMode ? "Atualizar" : "Criar"}
        </Button>
      </div>
    </Form>
  );
};

export default PaymentMethodForm;
