import { gql } from '@apollo/client';

export const GET_OPERATIONS = gql`
  query GetOperations($page: Int, $perPage: Int, $search: String) {
    operations(page: $page, perPage: $perPage, search: $search) {
      edges {
        node {
          id
          name
          default
          active
          requiresPayment
          createdAt
          updatedAt
        }
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`;

export const CREATE_OPERATION = gql`
  mutation CreateOperation($attributes: OperationAttributes!) {
    createOperation(input: { attributes: $attributes }) {
      operation {
        id
        name
        default
        active
        requiresPayment
        createdAt
        updatedAt
      }
      errors
    }
  }
`;

export const UPDATE_OPERATION = gql`
  mutation UpdateOperation($id: ID!, $attributes: OperationAttributes!) {
    updateOperation(input: { id: $id, attributes: $attributes }) {
      operation {
        id
        name
        default
        active
        requiresPayment
        createdAt
        updatedAt
      }
      errors
    }
  }
`;

export const UPDATE_OPERATION_STATUS = gql`
  mutation UpdateOperationStatus($id: ID!, $active: Boolean!) {
    updateOperationStatus(input: { id: $id, active: $active }) {
      operation {
        id
        active
      }
      errors
    }
  }
`;