import { gql } from '@apollo/client';

export const GET_PRICE_SEGMENTATIONS_COUNT = gql`
  query priceSegmentationsCount($active: Boolean) {
    priceSegmentationsCount(active: $active)
  }
`;

export const GET_ACTIVE_PRICE_SEGMENTATIONS = gql`
  query GetActivePriceSegmentations {
    activePriceSegmentations {
      id
      name
    }
  }
`;

export const GET_PRICE_SEGMENTATIONS = gql`
  query GetPriceSegmentations($page: Int, $perPage: Int, $search: String) {
    priceSegmentations(page: $page, perPage: $perPage, search: $search) {
      edges {
        node {
          id
          name
          hierarchy
          promotional
          initialDate
          expirationDate
          active
          createdAt
          updatedAt
        }
      }
      totalCount
    }
  }
`;

export const CREATE_PRICE_SEGMENTATION = gql`
  mutation CreatePriceSegmentation($attributes: PriceSegmentationAttributes!) {
    createPriceSegmentation(input: { attributes: $attributes }) {
      priceSegmentation {
        id
        name
        hierarchy
        promotional
        active
        initialDate
        expirationDate
        createdAt
        updatedAt
      }
      errors
    }
  }
`;

export const UPDATE_PRICE_SEGMENTATION = gql`
  mutation UpdatePriceSegmentation($id: ID!, $attributes: PriceSegmentationAttributes!) {
    updatePriceSegmentation(input: { id: $id, attributes: $attributes }) {
      priceSegmentation {
        id
        name
        hierarchy
        promotional
        initialDate
        expirationDate
        active
        createdAt
        updatedAt
      }
      errors
    }
  }
`;

export const UPDATE_PRICE_SEGMENTATION_STATUS = gql`
  mutation UpdatePriceSegmentationStatus($id: ID!, $active: Boolean!) {
    updatePriceSegmentationStatus(input: { id: $id, active: $active }) {
      priceSegmentation {
        id
        active
      }
      errors
    }
  }
`;

export const SEARCH_PRICE_SEGMENTATIONS = gql`
  query SearchPriceSegmentations($query: String!) {
    searchPriceSegmentations(query: $query) {
      id
      name
    }
  }
`;