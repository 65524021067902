import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Alert, Select, DatePicker, Row, Col } from 'antd';
import { useLazyQuery } from '@apollo/client';
import moment from 'moment';
import { SEARCH_CUSTOMERS } from '../mutations/customers';
import { SEARCH_SELLERS } from '../mutations/sellers';
import useDebounce from '../hooks/useDebounce';

const { RangePicker } = DatePicker;
const { Option } = Select;

const ScheduleForm = ({ initialValues, isEditMode, onFinish, onCancel, errorMessage }) => {
  const [form] = Form.useForm();

  // Estados para clientes
  const [searchTermCustomer, setSearchTermCustomer] = useState('');
  const [customers, setCustomers] = useState([]);
  const [loadingCustomers, setLoadingCustomers] = useState(false);
  const debouncedSearchTermCustomer = useDebounce(searchTermCustomer, 500);

  // Estados para vendedores
  const [searchTermSeller, setSearchTermSeller] = useState('');
  const [sellers, setSellers] = useState([]);
  const [loadingSellers, setLoadingSellers] = useState(false);
  const debouncedSearchTermSeller = useDebounce(searchTermSeller, 500);

  // Queries de pesquisa
  const [searchCustomers, { data: customersData }] = useLazyQuery(SEARCH_CUSTOMERS, {
    onCompleted: () => setLoadingCustomers(false),
  });
  const [searchSellers, { data: sellersData }] = useLazyQuery(SEARCH_SELLERS, {
    onCompleted: () => setLoadingSellers(false),
  });

  useEffect(() => {
    if (initialValues) {
      // Adiciona o cliente e o vendedor selecionados à lista de opções, se não estiverem lá
      if (initialValues.customer) {
        const customerExists = customers.some(customer => customer.id === initialValues.customer.id);
        if (!customerExists) {
          setCustomers(prev => [...prev, { id: initialValues.customer.id, name: initialValues.customer.name }]);
        }
      }
      if (initialValues.seller) {
        const sellerExists = sellers.some(seller => seller.id === initialValues.seller.id);
        if (!sellerExists) {
          setSellers(prev => [...prev, { id: initialValues.seller.id, name: initialValues.seller.name }]);
        }
      }

      form.setFieldsValue({
        ...initialValues,
        customerId: initialValues.customer?.id, // Define o ID do cliente
        sellerId: initialValues.seller?.id,     // Define o ID do vendedor
        timeRange: [moment(initialValues.startDate), moment(initialValues.endDate)],
      });
    } else {
      form.resetFields();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues, form]);

  // Efeito para pesquisa de clientes
  useEffect(() => {
    if (debouncedSearchTermCustomer.length > 2 || debouncedSearchTermCustomer === '*') {
      setLoadingCustomers(true);
      searchCustomers({ variables: { query: debouncedSearchTermCustomer } });
    }
  }, [debouncedSearchTermCustomer, searchCustomers]);

  // Efeito para pesquisa de vendedores
  useEffect(() => {
    if (debouncedSearchTermSeller.length > 2 || debouncedSearchTermSeller === '*') {
      setLoadingSellers(true);
      searchSellers({ variables: { query: debouncedSearchTermSeller } });
    }
  }, [debouncedSearchTermSeller, searchSellers]);

  // Atualiza os resultados da pesquisa de clientes
  useEffect(() => {
    if (customersData) {
      setCustomers(customersData.searchCustomers);
    }
  }, [customersData]);

  // Atualiza os resultados da pesquisa de vendedores
  useEffect(() => {
    if (sellersData) {
      setSellers(sellersData.searchSellers);
    }
  }, [sellersData]);

  const handleSearchCustomer = (value) => {
    setSearchTermCustomer(value);
  };

  const handleSearchSeller = (value) => {
    setSearchTermSeller(value);
  };

  const handleSubmit = async (values) => {
    const [startDate, endDate] = values.timeRange;
    const { timeRange, ...otherValues } = values;
    const formattedValues = {
      ...otherValues,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    };
    if (onFinish) onFinish(formattedValues);
  };

  return (
    <Form form={form} onFinish={handleSubmit} layout="vertical" initialValues={initialValues}>
      {errorMessage && <Alert message={errorMessage} type="error" showIcon />}

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="customerId" label="Cliente" rules={[{ required: true, message: 'Por favor selecione o cliente' }]}>
            <Select
              showSearch
              placeholder="Pesquisar cliente"
              onSearch={handleSearchCustomer}
              filterOption={false}
              loading={loadingCustomers}
              options={customers.map(customer => ({ value: customer.id, label: customer.name }))}
              notFoundContent={loadingCustomers ? 'Carregando...' : 'Nenhum cliente encontrado'}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="sellerId" label="Vendedor" rules={[{ required: true, message: 'Por favor selecione o vendedor' }]}>
            <Select
              showSearch
              placeholder="Pesquisar vendedor"
              onSearch={handleSearchSeller}
              filterOption={false}
              loading={loadingSellers}
              options={sellers.map(seller => ({ value: seller.id, label: seller.name }))}
              notFoundContent={loadingSellers ? 'Carregando...' : 'Nenhum vendedor encontrado'}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="kind" label="Tipo de Agenda" rules={[{ required: true, message: 'Por favor selecione o tipo de agenda' }]}>
            <Select placeholder="Selecione um tipo">
              <Option value="visit">Visita</Option>
              <Option value="prospect">Prospecção</Option>
              <Option value="call">Ligação</Option>
              <Option value="meeting">Reunião</Option>
              <Option value="task">Tarefa</Option>
              <Option value="email">E-mail</Option>
              <Option value="message">Mensagem</Option>
              <Option value="other">Outro</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="timeRange"
            label="Data e Hora"
            rules={[{ required: true, message: 'Por favor selecione a data e hora' }]}
          >
            <RangePicker format="DD/MM/YYYY HH:mm" showTime={{ format: 'HH:mm' }} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24}>
          <Form.Item name="description" label="Descrição" >
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>
      </Row>

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={onCancel} style={{ marginRight: 8 }}>
          Cancelar
        </Button>
        <Button type="primary" htmlType="submit">
          {isEditMode ? 'Atualizar' : 'Criar'}
        </Button>
      </div>
    </Form>
  );
};

export default ScheduleForm;
