import React, { useEffect } from 'react';
import { Form, Input, Button, Alert, Row, Col, Switch, DatePicker, InputNumber } from 'antd';
import dayjs from 'dayjs';

const PriceSegmentationForm = ({ initialValues, isEditMode, onFinish, onCancel, errorMessage }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue({
        ...initialValues,
        initialDate: initialValues.initialDate ? dayjs(initialValues.initialDate) : dayjs(),
        expirationDate: initialValues.expirationDate ? dayjs(initialValues.expirationDate) : dayjs(),
      });
    } else {
      form.setFieldsValue({ active: true });
    }
  }, [initialValues, form]);

  const handleFinish = (values) => {
    onFinish(values);
  };

  return (
    <Form
      form={form}
      initialValues={{
        ...initialValues,
        initialDate: initialValues?.initialDate ? dayjs(initialValues?.initialDate) : dayjs(),
        expirationDate: initialValues?.expirationDate ? dayjs(initialValues?.expirationDate) : dayjs(),
      }}
      onFinish={handleFinish}
      layout="vertical"
      style={{ width: '100%' }}
    >
      {errorMessage && <Alert message={errorMessage} type="error" showIcon />}
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="name" label="Nome" rules={[{ required: true, message: 'Por favor insira o nome' }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="hierarchy" label="Hierarquia">
            <InputNumber min={0} placeholder="Hierarquia" style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="initialDate" label="Data Inicial">
            <DatePicker
              placeholder="Data Inicial"
              format="DD/MM/YYYY"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="expirationDate" label="Data de Expiração">
            <DatePicker
              placeholder="Data de Expiração"
              format="DD/MM/YYYY"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="promotional" label="Promocional" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="active" label="Ativo" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end" gutter={16}>
        <Col>
          <Button onClick={onCancel} style={{ marginRight: 8, backgroundColor: 'red', color: 'white' }}>
            Cancelar
          </Button>
        </Col>
        <Col>
          <Button type="primary" htmlType="submit">
            {isEditMode ? "Atualizar" : "Criar"}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default PriceSegmentationForm;
