import React, { useState, useEffect } from 'react';
import { useLazyQuery, useMutation, gql } from '@apollo/client';
import { Table, Spin, Pagination, Input, Button, Modal, Switch } from 'antd';
import { format } from 'date-fns';
import { CREATE_CHECKIN_REASON, GET_CHECKIN_REASONS, UPDATE_CHECKIN_REASON, UPDATE_CHECKIN_REASON_STATUS } from '../mutations/checkinReasons';
import CheckinReasonForm from '../components/CheckinReasonForm';

const CheckinReasons = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isFormModalVisible, setIsFormModalVisible] = useState(false);
  const [selectedCheckinReason, setSelectedCheckinReason] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [fetchCheckinReasons, { loading, error, data }] = useLazyQuery(GET_CHECKIN_REASONS, {
    variables: { page: currentPage, perPage: pageSize, search: searchQuery },
  });

  const [createCheckinReason] = useMutation(CREATE_CHECKIN_REASON, {
    update: (cache, { data: { createCheckinReason } }) => {
      const { checkinReason } = createCheckinReason;
      if (!checkinReason) return;
      cache.modify({
        fields: {
          checkinReasons(existingCheckinReasons = { edges: [] }) {
            const newCheckinReasonRef = cache.writeFragment({
              data: checkinReason,
              fragment: gql`
                fragment NewCheckinReason on CheckinReason {
                  id
                  description
                  active
                }
              `
            });
            return {
              ...existingCheckinReasons,
              edges: [...existingCheckinReasons.edges, { node: newCheckinReasonRef }],
            };
          }
        }
      });
    },
  });

  const [updateCheckinReason] = useMutation(UPDATE_CHECKIN_REASON);
  const [updateCheckinReasonStatus] = useMutation(UPDATE_CHECKIN_REASON_STATUS);

  useEffect(() => {
    fetchCheckinReasons();
  }, [fetchCheckinReasons]);

  const handleSearch = () => {
    setSearchQuery(searchTerm);
    setCurrentPage(1);
    fetchCheckinReasons({
      variables: {
        page: 1,
        perPage: pageSize,
        search: searchTerm,
      },
    });
  };

  const handleCreateCheckinReason = () => {
    setSelectedCheckinReason(null);
    setIsEditMode(false);
    setIsFormModalVisible(true);
  };

  const handleEditCheckinReason = (checkinReason) => {
    setSelectedCheckinReason(checkinReason);
    setIsEditMode(true);
    setIsFormModalVisible(true);
  };

  const handleFormCancel = () => {
    setIsFormModalVisible(false);
    setSelectedCheckinReason(null);
    setErrorMessage(null);
  };

  const handleSave = async (values) => {
    setErrorMessage(null);
    try {
      let response;
      if (isEditMode) {
        response = await updateCheckinReason({
          variables: {
            id: selectedCheckinReason.id,
            description: values.description,
            active: values.active !== undefined ? values.active : true,
          }
        });
      } else {
        response = await createCheckinReason({
          variables: {
            description: values.description,
            active: values.active !== undefined ? values.active : true,
          }
        });
      }

      const errors = response.data?.createCheckinReason?.errors || response.data?.updateCheckinReason?.errors;
      if (errors && errors.length > 0) {
        setErrorMessage(errors.join(', '));
      } else {
        fetchCheckinReasons(); // Refetch Motivos de não vendas to update the list
        handleFormCancel(); // Close the modal
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(error.message || 'Ocorreu um erro inesperado.');
    }
  };

  const showConfirmModal = (checkinReason) => {
    setSelectedCheckinReason(checkinReason);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    setIsModalVisible(false);
    const { id, active } = selectedCheckinReason;
    try {
      await updateCheckinReasonStatus({
        variables: { id: id, active: !active },
        update: (cache, { data: { updateCheckinReasonStatus } }) => {
          const { checkinReason } = updateCheckinReasonStatus;
          cache.modify({
            id: cache.identify(checkinReason),
            fields: {
              active(cachedActive) {
                return !active;
              }
            }
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  if (loading) return <Spin />;
  if (error) return <p>Error: {error.message}</p>;

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Criado em',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: (text) => format(new Date(text), 'dd/MM/yyyy - HH:mm'),
    },
    {
      title: 'Atualizado em',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text) => format(new Date(text), 'dd/MM/yyyy HH:mm'),
    },
    {
      title: 'Ativo',
      dataIndex: 'active',
      key: 'active',
      align: 'center',
      render: (active, record) => (
        <Switch
          checked={active}
          onChange={() => showConfirmModal(record)}
        />
      ),
    },
    {
      title: 'Ações',
      key: 'actions',
      render: (text, record) => (
        <Button type="link" onClick={() => handleEditCheckinReason(record)}>Editar</Button>
      ),
    },
  ];

  const checkinReasons = data?.checkinReasons?.edges.map(edge => edge.node) || [];
  const { totalCount } = data?.checkinReasons || { pageInfo: {}, totalCount: 0 };

  return (
    <>
      <div style={{ marginBottom: 16, display: 'flex', alignItems: 'center' }}>

        <Input
          placeholder="Pesquisar"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginRight: 8, width: 300 }}
          onPressEnter={handleSearch}
        />
        <Button type="primary" onClick={handleSearch}>Pesquisar</Button>
        <Button type="primary" onClick={handleCreateCheckinReason} style={{ marginLeft: 'auto' }} >
          Criar Motivo
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={checkinReasons}
        rowKey="id"
        pagination={false}
      />
      {data?.checkinReasons?.pageInfo && (
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={totalCount}
          onChange={(page, pageSize) => {
            setCurrentPage(page);
            setPageSize(pageSize);
            fetchCheckinReasons({ variables: { page, perPage: pageSize, search: searchQuery } });
          }}
        />
      )}
      <Modal
        title={isEditMode ? 'Editar Motivos de não venda' : 'Criar Motivos de não venda'}
        open={isFormModalVisible}
        footer={null}
        onCancel={handleFormCancel}
      >
        <CheckinReasonForm
          initialValues={selectedCheckinReason || { description: '', active: true }}
          isEditMode={isEditMode}
          onFinish={handleSave}
          onCancel={handleFormCancel}
          errorMessage={errorMessage}
        />
      </Modal>
      <Modal
        title="Confirmar Alteração"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={() => setIsModalVisible(false)}
        okText="Confirmar"
        cancelText="Cancelar"
      >
        <p>Você realmente deseja {selectedCheckinReason?.active ? 'desativar' : 'ativar'} este motivo de check-in?</p>
      </Modal>
    </>
  );
};

export default CheckinReasons;
