import React, { useEffect } from 'react';
import { Form, Input, Button, Alert } from 'antd';

const LinkForm = ({ initialValues, isEditMode, onFinish, onCancel, errorMessage }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [initialValues, form]);

  return (
    <Form
      form={form}
      initialValues={initialValues}
      onFinish={onFinish}
      layout="vertical"
    >
      {errorMessage && <Alert message={errorMessage} type="error" showIcon />}
      <Form.Item name="name" label="Nome" rules={[{ required: true, message: 'Por favor insira o nome' }]}>
        <Input />
      </Form.Item>
      <Form.Item name="url" label="URL" rules={[{ required: true, message: 'Por favor insira a URL' }]}>
        <Input />
      </Form.Item>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={onCancel} style={{ marginRight: 8, backgroundColor: 'red', color: 'white' }}>
          Cancelar
        </Button>
        <Button type="primary" htmlType="submit">
          {isEditMode ? "Atualizar" : "Criar"}
        </Button>
      </div>
    </Form>
  );
};

export default LinkForm;
