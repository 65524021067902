import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, gql } from '@apollo/client';
import { Form, Input, Button, message, Row, Col, Card, Image } from 'antd';

const RESET_PASSWORD = gql`
  mutation ResetPassword($token: String!, $password: String!, $passwordConfirmation: String!) {
    resetPassword(input: {token: $token, password: $password, passwordConfirmation: $passwordConfirmation}) {
      success
      errors
    }
  }
`;

const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const token = query.get('token') || query.get('reset_password_token');

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [resetPassword, { loading, error }] = useMutation(RESET_PASSWORD);

  const handleResetPassword = async () => {
    const response = await resetPassword({ variables: { token, password, passwordConfirmation } });
    if (response.data.resetPassword.success) {
      message.success('Senha redefinida com sucesso.');
      navigate('/login');
    } else {
      message.error(response.data.resetPassword.errors.join(', '));
    }
  };

  return (
    <Row justify="center" align="middle" style={{ height: '100vh' }}>
      <Col xs={22} sm={16} md={12} lg={8} xl={6}>
        <Card>
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            <Image src={'/logo192.png'} alt="Logo" preview={false} width={400} />
          </div>
          <h2>Redefinir Senha</h2>
          <Form onFinish={handleResetPassword}>
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Por favor, insira sua nova senha!' }]}
            >
              <Input.Password
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Digite sua nova senha"
              />
            </Form.Item>
            <Form.Item
              name="passwordConfirmation"
              rules={[{ required: true, message: 'Por favor, confirme sua nova senha!' }]}
            >
              <Input.Password
                value={passwordConfirmation}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
                placeholder="Confirme sua nova senha"
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading} block>
                Redefinir senha
              </Button>
              <Button type="link" onClick={() => navigate('/register')} block>
                Não possui conta? Registre-se
              </Button>
              <Button type="link" onClick={() => navigate('/request-password-reset')} block>
                Esqueceu a senha?
              </Button>
              <Button type="link" onClick={() => navigate('/resend-confirmation-email')} block>
                Não recebeu o e-mail de confirmação?
              </Button>
              <Button type="link" onClick={() => navigate('/login')} block>
                Já tem conta? Login
              </Button>
            </Form.Item>
            {error && <p>Erro ao redefinir senha</p>}
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default ResetPassword;