import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Alert, Row, Col, Switch, Select, InputNumber } from 'antd';
import { useLazyQuery } from '@apollo/client';
import { CloseCircleOutlined } from '@ant-design/icons';
import { SEARCH_PAYMENT_METHODS } from '../../mutations/paymentMethods';
import { SEARCH_PAYMENT_CONDITIONS } from '../../mutations/paymentConditions';
import useDebounce from '../../hooks/useDebounce';
const { Option } = Select;

const PaymentProfileForm = ({ initialValues, isEditMode, onFinish, onCancel, errorMessage, paymentMethods = [], paymentConditions = [] }) => {
  const [form] = Form.useForm();
  const [searchPaymentMethods, { data: paymentMethodsData }] = useLazyQuery(SEARCH_PAYMENT_METHODS);
  const [searchPaymentConditions, { data: paymentConditionsData }] = useLazyQuery(SEARCH_PAYMENT_CONDITIONS);

  const [paymentMethodsList, setPaymentMethodsList] = useState(paymentMethods);
  const [paymentConditionsList, setPaymentConditionsList] = useState(paymentConditions);

  const [paymentMethodSearchTerm, setPaymentMethodSearchTerm] = useState('');
  const [paymentConditionSearchTerm, setPaymentConditionSearchTerm] = useState('');

  const debouncedPaymentMethodSearchTerm = useDebounce(paymentMethodSearchTerm, 500);
  const debouncedPaymentConditionSearchTerm = useDebounce(paymentConditionSearchTerm, 500);

  useEffect(() => {
    if (debouncedPaymentMethodSearchTerm.length > 2 || debouncedPaymentMethodSearchTerm === "*") {
      searchPaymentMethods({ variables: { query: debouncedPaymentMethodSearchTerm } });
    }
  }, [debouncedPaymentMethodSearchTerm, searchPaymentMethods]);

  useEffect(() => {
    if (debouncedPaymentConditionSearchTerm.length > 2 || debouncedPaymentConditionSearchTerm === '*') {
      searchPaymentConditions({ variables: { query: debouncedPaymentConditionSearchTerm } });
    }
  }, [debouncedPaymentConditionSearchTerm, searchPaymentConditions]);

  useEffect(() => {
    if (paymentMethodsData) {
      setPaymentMethodsList(paymentMethodsData.searchPaymentMethods);
    }
  }, [paymentMethodsData]);

  useEffect(() => {
    if (paymentConditionsData) {
      setPaymentConditionsList(paymentConditionsData.searchPaymentConditions);
    }
  }, [paymentConditionsData]);

  useEffect(() => {
    if (initialValues) {
      const profileMethodConditions = initialValues?.profileMethodConditions?.map((condition, index) => ({
        key: index,
        id: condition.id,
        paymentMethod: {
          value: condition.paymentMethodId,
          label: condition.paymentMethod.name,
        },
        paymentCondition: {
          value: condition.paymentConditionId,
          label: condition.paymentCondition.name,
        },
        minimumValue: condition.minimumValue,
      }));
      form.setFieldsValue({
        ...initialValues,
        profileMethodConditions,
      });
    } else {
      form.setFieldsValue({ active: true, profileMethodConditions: [{}] });
    }
  }, [initialValues, form]);

  const handleSearchPaymentMethod = (value) => {
    setPaymentMethodSearchTerm(value);
  };

  const handleSearchPaymentCondition = (value) => {
    setPaymentConditionSearchTerm(value);
  };

  const transformFormValues = (values) => {
    const profileMethodConditions = values.profileMethodConditions.map(condition => ({
      id: condition.id,
      paymentMethodId: condition.paymentMethod.value || condition.paymentMethod,
      paymentConditionId: condition.paymentCondition.value || condition.paymentCondition,
      minimumValue: condition.minimumValue,
      _destroy: condition._destroy || false
    }));
    return { ...values, profileMethodConditions };
  };

  const handleFinish = (values) => {
    onFinish(transformFormValues(values));
  };

  return (
    <Form
      form={form}
      initialValues={initialValues}
      onFinish={handleFinish}
      layout="vertical"
      style={{ width: '100%' }}
    >
      {errorMessage && <Alert message={errorMessage} type="error" showIcon />}
      <Row gutter={16}>
        <Col span={18}>
          <Form.Item name="name" label="Nome" rules={[{ required: true, message: 'Por favor insira o nome' }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="active" label="Ativo" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Col>
      </Row>
      <Form.List name="profileMethodConditions">
        {(fields, { add, remove }) => (
          <>
            {fields?.map(({ key, name, ...restField }, index) => {
              const initialPaymentMethodValue = initialValues?.profileMethodConditions[index]?.paymentMethodId
                ? { value: initialValues.profileMethodConditions[index].paymentMethodId, label: initialValues.profileMethodConditions[index].paymentMethod.name }
                : undefined;
              const initialPaymentConditionValue = initialValues?.profileMethodConditions[index]?.paymentConditionId
                ? { value: initialValues.profileMethodConditions[index].paymentConditionId, label: initialValues.profileMethodConditions[index].paymentCondition.name }
                : undefined;

              return (
                <Row key={key} gutter={16} align="middle">
                   <Form.Item
                      {...restField}
                      name={[name, 'id']}
                      key={[key, 'id']}
                      hidden={true}
                    ></Form.Item>
                  <Col span={7}>
                    <Form.Item
                      {...restField}
                      name={[name, 'paymentMethod']}
                      key={[key, 'paymentMethod']}
                      label="Forma de Pagamento"
                      rules={[{ required: true, message: 'Selecione a forma de pagamento' }]}
                    >
                      <Select
                        showSearch
                        placeholder="Pesquisar forma de pagamento"
                        onSearch={handleSearchPaymentMethod}
                        filterOption={false}
                        options={paymentMethodsList?.map(method => ({ value: method.id, label: method.name }))}
                        defaultValue={initialPaymentMethodValue}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={7}>
                    <Form.Item
                      {...restField}
                      name={[name, 'paymentCondition']}
                      key={[key, 'paymentCondition']}
                      label="Condição de Pagamento"
                      rules={[{ required: true, message: 'Selecione a condição de pagamento' }]}
                    >
                      <Select
                        showSearch
                        placeholder="Pesquisar condição de pagamento"
                        onSearch={handleSearchPaymentCondition}
                        filterOption={false}
                        options={paymentConditionsList?.map(condition => ({ value: condition.id, label: condition.name }))}
                        defaultValue={initialPaymentConditionValue}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      {...restField}
                      name={[name, 'minimumValue']}
                      key={[key, 'minimumValue']}
                      label="Valor Mínimo"
                      rules={[{ required: true, message: 'Insira o valor mínimo' }]}
                    >
                      <InputNumber min={0} placeholder="Valor Mínimo" style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                  <Col span={2} style={{ textAlign: 'center' }}>
                    <Button
                      type="text"
                      icon={<CloseCircleOutlined style={{ color: 'red' }} />}
                      onClick={() => remove(name)}
                    />
                  </Col>
                </Row>
              );
            })}
            <Form.Item>
              <Button type="dashed" onClick={() => add()} block>
                Adicionar Forma de Pagamento
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      <Row justify="end" gutter={16}>
        <Col>
          <Button onClick={onCancel} style={{ marginRight: 8, backgroundColor: 'red', color: 'white' }}>
            Cancelar
          </Button>
        </Col>
        <Col>
          <Button type="primary" htmlType="submit">
            {isEditMode ? "Atualizar" : "Criar"}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default PaymentProfileForm;
