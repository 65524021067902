import { gql } from '@apollo/client';

export const GET_SCHEDULES = gql`
  query GetSchedules($page: Int, $perPage: Int, $search: String) {
    schedules(page: $page, perPage: $perPage, search: $search) {
      edges {
        node {
          id
          customer {
            id
            name
          }
          seller {
            id
            name
            email
          }
          kind
          startDate
          endDate
          description
          createdAt
          updatedAt
        }
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`;

export const CREATE_SCHEDULE = gql`
  mutation CreateSchedule($attributes: ScheduleAttributes!) {
    createSchedule(input: { attributes: $attributes }) {
      schedule {
        id
        customer {
          id
          name
        }
        seller {
          id
          name
          email
        }    
        kind
        startDate
        endDate
        description
        createdAt
        updatedAt
      }
      errors
    }
  }
`;

export const UPDATE_SCHEDULE = gql`
  mutation UpdateSchedule($id: ID!, $attributes: ScheduleAttributes!) {
    updateSchedule(input: { id: $id, attributes: $attributes }) {
      schedule {
        id
        customer {
          id
          name
        }
        seller {
          id
          name
          email
        }
        kind
        startDate
        endDate
        description
        createdAt
        updatedAt
      }
      errors
    }
  }
`;
