import { gql, useMutation } from '@apollo/client';

export const GET_SELLERS_COUNT = gql `
query sellersCount($active: Boolean) {
    sellersCount(active: $active) 
}
`;

export const SEARCH_SELLERS = gql`
  query searchSellers($query: String!) {
    searchSellers(query: $query) {
      id
      name
      email
      active
    }
  }
`;


export const GET_SELLERS = gql`
  query GetSellers($page: Int, $perPage: Int, $search: String) {
    sellers(page: $page, perPage: $perPage, search: $search) {
      edges {
        node {
          id
          name
          email
          active
          placeIds
          permissions {
            viewAllCustomers
            canSellDefaulterClients
            maximumDiscountPercentage
            canEditProductValue
            canSellBelowMinimumValue
            maximumDiscountPercentagePerProduct
            minimumProfitMargin
            minimumOrderProfitMargin
            canSellBelowMinimumProfitMargin
            canSellBelowMinimumOrderProfitMargin
            viewProfitValue
            canSellWithoutStock
            canChangePriceSegmentation
            canUpdateCustomer
            canViewMinimumValue
            canInsertCustomer
            canViewStock
            checkinOnOrder
            canSellInCash
            canDeleteSchedule
          }
        }
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`;

export const UPDATE_SELLER_STATUS = gql`
  mutation UpdateSellerStatus($id: ID!, $active: Boolean!) {
    updateSellerStatus(input: {id: $id, active: $active}) {
      seller {
        id
        active
      }
      errors
    }
  }
`;

export const GET_CURRENT_SELLER = gql`
  query GetCurrentSeller {
    seller {
      id
      name
      email
      active
      placeIds
      whatsapp
      plugchatConnected
      createdAt
    }
  }
`;



export const UPDATE_SELLER = gql`
  mutation UpdateSeller($id: ID!, $attributes: SellerAttributes!) {
    updateSeller(input: { id: $id, attributes: $attributes }) {
      seller {
        id
        name
        email
        active
        whatsapp
        placeIds
        createdAt
        permissions {
          viewAllCustomers
          canSellDefaulterClients
          maximumDiscountPercentage
          canEditProductValue
          canSellBelowMinimumValue
          maximumDiscountPercentagePerProduct
          minimumProfitMargin
          minimumOrderProfitMargin
          canSellBelowMinimumProfitMargin
          canSellBelowMinimumOrderProfitMargin
          viewProfitValue
          canSellWithoutStock
          canChangePriceSegmentation
          canUpdateCustomer
          canViewMinimumValue
          canInsertCustomer
          canViewStock
          checkinOnOrder
          canSellInCash
          canDeleteSchedule
        }
      }
      errors
    }
  }
`;
export const UPDATE_SELLER_PM = gql`
  mutation UpdateSellerPedidosMoveis($id: ID!, $attributes: SellerAttributes!) {
    updateSellerPedidosMoveis(input: { id: $id, attributes: $attributes }) {
      seller {
        id
        name
        email
        active
        createdAt
        placeIds
        permissions {
          viewAllCustomers
          canSellDefaulterClients
          maximumDiscountPercentage
          canEditProductValue
          canSellBelowMinimumValue
          maximumDiscountPercentagePerProduct
          minimumProfitMargin
          minimumOrderProfitMargin
          canSellBelowMinimumProfitMargin
          canSellBelowMinimumOrderProfitMargin
          viewProfitValue
          canSellWithoutStock
          canChangePriceSegmentation
          canUpdateCustomer
          canViewMinimumValue
          canInsertCustomer
          canViewStock
          checkinOnOrder
          canSellInCash
          canDeleteSchedule
        }
      }
      errors
    }
  }
`;

export const CREATE_SELLER = gql`
  mutation CreateSeller($attributes: SellerAttributes!) {
    createSeller(input: {attributes: $attributes}) {
      seller {
        id
        name
        email
        active
        createdAt
        updatedAt
        placeIds
        permissions {
          viewAllCustomers
          canSellDefaulterClients
          maximumDiscountPercentage
          canEditProductValue
          canSellBelowMinimumValue
          maximumDiscountPercentagePerProduct
          minimumProfitMargin
          minimumOrderProfitMargin
          canSellBelowMinimumProfitMargin
          canSellBelowMinimumOrderProfitMargin
          viewProfitValue
          canSellWithoutStock
          canChangePriceSegmentation
          canUpdateCustomer
          canViewMinimumValue
          canInsertCustomer
          canViewStock
          checkinOnOrder
          canSellInCash
          canDeleteSchedule
          }
      }
      errors
    }
  }
`;

export const GET_SELLER_BY_ID = gql`
  query GetSellerById($id: ID!) {
    seller(id: $id) {
      id
      name
      email
      active
      permissions
      createdAt
      placeIds
      updatedAt
      permissions {
          viewAllCustomers
          canSellDefaulterClients
          maximumDiscountPercentage
          canEditProductValue
          canSellBelowMinimumValue
          maximumDiscountPercentagePerProduct
          minimumProfitMargin
          minimumOrderProfitMargin
          canSellBelowMinimumProfitMargin
          canSellBelowMinimumOrderProfitMargin
          viewProfitValue
          canSellWithoutStock
          canChangePriceSegmentation
          canUpdateCustomer
          canViewMinimumValue
          canInsertCustomer
          canViewStock
          checkinOnOrder
          canSellInCash
          canDeleteSchedule
          }
    }
  }
`;

export const useUpdateSeller = () => {
  return useMutation(UPDATE_SELLER);
};
export const useCreateSeller = () => {
  return useMutation(CREATE_SELLER);
};
export const useUpdateSellerPedidosMoveis = () => {
  return useMutation(UPDATE_SELLER_PM);
};
