import React from 'react';
import { Layout, Menu, Button, Image } from 'antd';
import { Link, Outlet, useLocation } from 'react-router-dom';
import useLogout from '../hooks/useLogout.jsx';
import {
  UserOutlined,
  CalendarOutlined,
  ShoppingCartOutlined,
  AppstoreOutlined,
  TeamOutlined,
  TagsOutlined,
  CreditCardOutlined,
  DollarOutlined,
  ContactsOutlined,
  ProfileOutlined,
  DashboardOutlined,
  LinkOutlined,
  HomeOutlined,
  UnorderedListOutlined
} from '@ant-design/icons';

const { Header, Content, Sider } = Layout;

const AppLayout = () => {
  const location = useLocation();
  const logout = useLogout();

  const menuItems = [
    {
      key: 'dashboard',
      label: <Link to="/dashboard">Dashboard</Link>,
      icon: <DashboardOutlined  />
    },
    {
      key: 'account-settings',
      label: <Link to="/account-settings">Conta</Link>,
      icon: <UserOutlined />
    },
    {
      key: 'places',
      label: <Link to="/places">Empresas</Link>,
      icon: <HomeOutlined />
    },
    {
      key: 'sellers',
      label: <Link to="/sellers">Vendedores</Link>,
      icon: <ContactsOutlined />
    },
    {
      key: 'orders',
      label: <Link to="/orders">Pedidos</Link>,
      icon: <ShoppingCartOutlined />
    },
    {
      key: 'groups',
      label: <Link to="/groups">Grupos</Link>,
      icon: <TagsOutlined />
    },
    {
      key: 'operations',
      label: <Link to="/operations">Operações</Link>,
      icon: <AppstoreOutlined />
    },
    {
      key: 'priceSegmentations',
      label: <Link to="/priceSegmentations">Tabelas de preço</Link>,
      icon: <AppstoreOutlined />
    },
    {
      key: 'products',
      label: <Link to="/products">Produtos</Link>,
      icon: <AppstoreOutlined />
    },
    {
      key: 'paymentMethods',
      label: <Link to="/paymentMethods">Formas de pagamento</Link>,
      icon: <CreditCardOutlined />
    },
    {
      key: 'paymentConditions',
      label: <Link to="/paymentConditions">Condições de pagamento</Link>,
      icon: <DollarOutlined />
    },
    {
      key: 'paymentProfiles',
      label: <Link to="/paymentProfiles">Perfis de pagamento</Link>,
      icon: <ProfileOutlined  />
    },
    {
      key: 'customers',
      label: <Link to="/customers">Clientes</Link>,
      icon: <TeamOutlined />
    },
    {
      key: 'links',
      label: <Link to="/links">Links</Link>,
      icon: <LinkOutlined />
    },
    {
      key: 'checkinReasons',
      label: <Link to="/checkinReasons">Motivos</Link>,
      icon: <UnorderedListOutlined />
    },
    {
      key: 'schedules',
      label: <Link to="/schedules">Agenda</Link>,
      icon: <CalendarOutlined />
    },
   
  ];

  const getSelectedKey = () => {
    const path = location.pathname;
    const menuItem = menuItems.find(item => path.includes(item.key));
    return menuItem ? menuItem.key : 'dashboard';
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header className="header" style={{ backgroundColor: '#fff', padding: '0 20px' }}>
        <div className="logo" style={{ float: 'left', marginLeft: '-10px' }}>
          <Image src={'coloridamodelo02.png'} alt="Logo" preview={false} width={200} />
        </div>
        <Button type="link" onClick={logout} style={{ float: 'right', marginTop: '10px', }}>
          Sair
        </Button>
      </Header>
      <Layout>
        <Sider width={255} className="site-layout-background">
          <Menu
            mode="inline"
            selectedKeys={[getSelectedKey()]}
            style={{ height: '100%', borderRight: 0 }}
            items={menuItems}
          />
        </Sider>
        <Layout style={{ padding: '0 24px 24px' }}>
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
