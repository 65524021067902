import React, { useEffect } from 'react';
import { Form, Input, Button, message, Row, Col, Card, Divider, Switch } from 'antd';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_ACCOUNT, UPDATE_ACCOUNT } from '../mutations/account';
import AddressForm from '../components/AddressForm';

const AccountSettings = () => {
  const [loadAccount, { loading: queryLoading, data }] = useLazyQuery(GET_ACCOUNT);
  const [storeAccount, { loading: mutationLoading }] = useMutation(UPDATE_ACCOUNT);

  const [form] = Form.useForm();

  useEffect(() => {
    loadAccount();
  }, [loadAccount]);


  useEffect(() => {
    if (data) {
      let cityData = {}
      if (data.account.address) {
        cityData = { value: data?.account?.address?.city?.id, label: `${data?.account?.address?.city?.name} - ${data?.account?.address?.city?.state?.initials}` }
      }
      form.setFieldsValue({
        ...data.account,
        address: {
          ...data.account.address,
          cityId: cityData
        }
      });
    }
  }, [data, form]);

  const handleUpdate = async (values) => {
    const addressValues = {
      ...values.address,
      cityId: values.address.cityId.value,
    };

    const { plugchatConnected, plugchatQrCode, ...filteredValues } = values;
    const finalValues = {
      ...filteredValues,
      address: addressValues,
    };
    try {
      const { data } = await storeAccount({
        variables: { attributes: finalValues }
      });
      if (data.storeAccount.errors.length > 0) {
        message.error(data.storeAccount.errors.join(', '));
      } else {
        message.success('Conta atualizada com sucesso');
      }
    } catch (error) {
      message.error('Falha ao atualizar a conta');
      console.error(error);
    }
  };

  if (queryLoading) return <p>Carregando...</p>;

  return (
    <Row justify="center" align="middle" style={{ height: '100%' }}>
      <Col span={24}>
        <Card>
          <h2>Dados da conta</h2>
          <Form
            form={form}
            initialValues={data?.account}
            onFinish={handleUpdate}
            layout="vertical"
          >
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item name="corporateName" label="Nome da Empresa">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="name" label="Razão Social">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="document" label="Documento">
                  <Input />
                </Form.Item>
              </Col>

            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item name="email" label="Email">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="phone" label="Telefone">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="domain" label="Dominio">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="initialCode" label="Código inicial">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="currentCode" label="Código atual do pedido">
                  <Input disabled={true} />
                </Form.Item>
              </Col>
            </Row>
            
            <Divider>Endereço</Divider>
            <AddressForm initialValues={data?.account?.address || {}} />
            <Divider>Configurações de Pesquisa</Divider>
            <Row gutter={16}>
              <Col justify="center" align="middle">
                <Form.Item name="searchLimit" label="Limite de resultados" >
                  <Input style={{ width: '50px' }} />
                </Form.Item>
              </Col>
              <Col >
                <Form.Item name="searchPartial" label="Parcial" valuePropName="checked">
                  <Switch />
                </Form.Item>
              </Col>
              <Col >
                <Form.Item name="anyWord" label="Por qualquer palavra" valuePropName="checked">
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
            <Divider>Carrinho</Divider>
            <Row gutter={16} >
              <Col justify="center" align="middle">
                <Form.Item name="abandonedCartTime" label="Tempo para abandonar (em horas)">
                  <Input style={{ width: '50px' }} />
                </Form.Item>
              </Col>
              <Col justify="center" align="middle">
                <Form.Item name="attemptsLimit" label="Tentativas de notificação">
                  <Input style={{ width: '50px' }} />
                </Form.Item>
              </Col>
              <Col justify="center" align="middle">
                <Form.Item name="notifyOnBusinessHours" label="Notifica em horário comercial" valuePropName="checked">
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item style={{ textAlign: 'right' }}>
              <Button type="primary" htmlType="submit" loading={mutationLoading} style={{ width: '200px' }}>
                Atualizar conta
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default AccountSettings;