import React, { useEffect } from 'react';
import { Form, Input, Button, Alert, Row, Col, Switch, InputNumber } from 'antd';

const PaymentConditionForm = ({ initialValues, isEditMode, onFinish, onCancel, errorMessage }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [initialValues, form]);

  const handleSubmit = (values) => {
    onFinish({ ...values, active: values.active || false, inCash: values.inCash || false });
  };

  return (
    <Form
      form={form}
      initialValues={initialValues}
      onFinish={handleSubmit}
      layout="vertical"
    >
      {errorMessage && <Alert message={errorMessage} type="error" showIcon />}
      <Row gutter={16}>
        <Col span={18}>
          <Form.Item name="name" label="Nome" rules={[{ required: true, message: 'Por favor insira o nome' }]}>
            <Input />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item name="installments" label="Parcelas" rules={[{ required: true, message: 'Por favor insira o número de parcelas' }]}>
            <InputNumber min={1} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col >
          <Form.Item name="active" label="Ativo" valuePropName="checked" >
            <Switch disabled={!isEditMode}/>
          </Form.Item>
        </Col>

        <Col >
          <Form.Item name="inCash" label="À Vista" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Col>
      </Row>


      <Row justify="end" gutter={16}>
        <Col>
          <Button onClick={onCancel} style={{ marginRight: 8, backgroundColor: 'red', color: 'white' }}>
            Cancelar
          </Button>
        </Col>
        <Col>
          <Button type="primary" htmlType="submit">
            {isEditMode ? "Atualizar" : "Criar"}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default PaymentConditionForm;
