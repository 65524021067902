import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { Form, Image, Input, Button, message, Row, Col, Card } from 'antd';
import { useNavigate } from 'react-router-dom';

const REQUEST_PASSWORD_RESET = gql`
  mutation RequestPasswordReset($email: String!) {
    requestPasswordReset(input: {email: $email}) {
      success
      errors
    }
  }
`;

const RequestPasswordReset = () => {
  const [email, setEmail] = useState('');
  const [requestPasswordReset, { loading, error }] = useMutation(REQUEST_PASSWORD_RESET);
  const navigate = useNavigate();

  const handleRequestReset = async () => {
    const response = await requestPasswordReset({ variables: { email } });
    if (response.data.requestPasswordReset.success) {
      message.success('E-mail de redefinição enviado!');
    } else {
      message.error(response.data.requestPasswordReset.errors.join(', '));
    }
  };

  return (
    <Row justify="center" align="middle" style={{ height: '100vh' }}>
      <Col xs={22} sm={16} md={12} lg={8} xl={6}>
      <Card>
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            <Image src={'/logo192.png'} alt="Logo" preview={false} width={400} />
          </div>
          <h2>Redefinir Senha</h2>
          <Form onFinish={handleRequestReset}>
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Por favor, preencha seu email!' }]}
            >
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Digite seu email"
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading} block>
                Enviar instruções de redefinição de senha
              </Button>
            </Form.Item>
            <Form.Item>
              <Button type="link" onClick={() => navigate('/register')} block>
                Não possui conta? Registre-se
              </Button>
              <Button type="link" onClick={() => navigate('/login')} block>
                Já tem conta? Login
              </Button>
            </Form.Item>
            {error && <p>Erro ao solicitar redefinição de senha</p>}
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default RequestPasswordReset;