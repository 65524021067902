import { gql } from "@apollo/client";

export const GET_USER_TOUR_STATUS = gql`
  query GetUserTourStatus {
    userTourStatus
  }
`;

export const UPDATE_TOUR_STATUS = gql`
  mutation UpdateTourStatus($tourCompleted: Boolean!) {
    updateTourStatus(input: { tourCompleted: $tourCompleted }) {
      user {
        id
        tourCompleted
      }
      errors
    }
  }
`;