import React, { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Table, Spin, Pagination, Input, Button, Modal, Switch } from 'antd';
import { GET_SELLERS, UPDATE_SELLER_STATUS, CREATE_SELLER, UPDATE_SELLER_PM } from '../mutations/sellers';
import SellerForm from '../components/SellerForm';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;

const Sellers = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isFormModalVisible, setIsFormModalVisible] = useState(false);
  const [selectedSeller, setSelectedSeller] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [fetchSellers, { loading, error, data }] = useLazyQuery(GET_SELLERS, {
    variables: { page: currentPage, perPage: pageSize, search: searchQuery },
  });

  const [createSeller] = useMutation(CREATE_SELLER, {
    update(cache, { data: { createSeller } }) {
      const newSeller = createSeller.seller;
      const existingSellers = cache.readQuery({
        query: GET_SELLERS,
        variables: { page: currentPage, perPage: pageSize, search: searchQuery },
      });

      cache.writeQuery({
        query: GET_SELLERS,
        variables: { page: currentPage, perPage: pageSize, search: searchQuery },
        data: {
          sellers: {
            ...existingSellers.sellers,
            edges: [{ node: newSeller }, ...existingSellers.sellers.edges],
            totalCount: existingSellers.sellers.totalCount + 1,
          },
        },
      });
    },
  });
  const [updateSellerPedidosMoveis] = useMutation(UPDATE_SELLER_PM);

  useEffect(() => {
    fetchSellers();
  }, [fetchSellers]);

  const handleFinish = async (values) => {
    try {
      const mutation = isEditMode ? updateSellerPedidosMoveis : createSeller;
      const variables = isEditMode ? { id: selectedSeller.id, attributes: values } : { attributes: values };
      const { data } = await mutation({ variables });

      if ((data.createSeller || data.updateSellerPedidosMoveis)?.errors?.length > 0) {
        setErrorMessage((data.createSeller || data.updateSeller).errors.join(', '));
      } else {
        handleFormCancel();
        fetchSellers();
      }
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const handleSearch = () => {
    setSearchQuery(searchTerm);
    setCurrentPage(1);
    fetchSellers({
      variables: {
        page: 1,
        perPage: pageSize,
        search: searchTerm,
      },
    });
  };

  const handleCreateSeller = () => {
    setSelectedSeller(null);
    setIsEditMode(false);
    setIsFormModalVisible(true);
  };

  const handleEditSeller = (seller) => {
    setSelectedSeller(seller);
    setIsEditMode(true);
    setIsFormModalVisible(true);
  };

  const handleFormCancel = () => {
    setIsFormModalVisible(false);
    setSelectedSeller(null);
    setErrorMessage(null);
  };

  const showConfirm = (id, active) => {
    confirm({
      title: `Você tem certeza que deseja ${active ? 'ativar' : 'desativar'} este perfil de pagamento?`,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        handleToggleActive(id, active);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const [updateSellerStatus] = useMutation(UPDATE_SELLER_STATUS);
  const handleToggleActive = async (id, active) => {
    try {
      await updateSellerStatus({ variables: { id, active } });
      fetchSellers();
    } catch (error) {
      console.error('Error updating seller status:', error);
    }
  };

  if (loading) return <Spin />;
  if (error) return <p>Error: {error.message}</p>;

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Ativo',
      dataIndex: 'active',
      key: 'active',
      render: (active, record) => (
        <Switch
          checked={active}
          onChange={() => showConfirm(record.id, !active)}
        />
      ),
    },
    {
      title: 'Ações',
      key: 'actions',
      render: (text, record) => (
        <Button type="link" onClick={() => handleEditSeller(record)}>Editar</Button>
      ),
    },
  ];

  const sellers = data?.sellers?.edges.map(edge => edge.node) || [];
  const { totalCount } = data?.sellers || { totalCount: 0 };

  return (
    <>
      <div style={{ marginBottom: 16, display: 'flex', alignItems: 'center' }}>
        <Input
          placeholder="Pesquisar"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginRight: 8, width: 300 }}
          onPressEnter={handleSearch}
        />
        <Button type="primary" onClick={handleSearch}>Pesquisar</Button>
        <Button type="primary" style={{ marginLeft: 'auto' }} onClick={handleCreateSeller}>
          Criar Vendedor
        </Button>
      </div>
      <Table dataSource={sellers} columns={columns} rowKey="id" pagination={false} />
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={totalCount}
        onChange={(page, pageSize) => {
          setCurrentPage(page);
          setPageSize(pageSize);
          fetchSellers({
            variables: {
              page: page,
              perPage: pageSize,
              search: searchQuery,
            },
          });
        }}
      />

      <SellerForm
        title={isEditMode ? "Editar Vendedor" : "Criar Vendedor"}
        initialValues={selectedSeller}
        isEditMode={isEditMode}
        visible={isFormModalVisible}
        onFinish={handleFinish}
        onCancel={handleFormCancel}
        errorMessage={errorMessage}
      />
    </>
  );
};

export default Sellers;