import React from 'react';
import { Form, Input, Button, message, Row, Col, Card, Image } from 'antd';
import { useMutation, gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

const REGISTER_MUTATION = gql`
  mutation RegisterPedidosMoveis($email: String!, $password: String!, $passwordConfirmation: String!, $name: String!) {
    registerPedidosMoveis(input: { email: $email, password: $password, passwordConfirmation: $passwordConfirmation, name: $name }) {
      user {
        id
        email
      }
      errors
    }
  }
`;

const RegisterForm = () => {
  const [register, { loading }] = useMutation(REGISTER_MUTATION);
  const navigate = useNavigate();

  const handleRegister = async (values) => {
    try {
      const { data } = await register({
        variables: {
          email: values.email,
          password: values.password,
          passwordConfirmation: values.password_confirmation,
          name: values.name
        },
      });
      if (data.registerPedidosMoveis.errors.length > 0) {
        message.error(data.registerPedidosMoveis.errors.join(', '));
      } else {
        message.success('Registration successful');
        navigate('/login');
      }
    } catch (error) {
      message.error('Registration failed');
      console.error(error);
    }
  };

  return (
    <Row justify="center" align="middle" style={{ height: '100vh' }}>
      <Col xs={22} sm={16} md={12} lg={8} xl={6}>
        <Card>
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            <Image src={'/logo192.png'} alt="Logo" preview={false} width={400} />
            <h2>Registro</h2>
          </div>
          <Form onFinish={handleRegister}>
            <Form.Item
              name="name"
              rules={[{ required: true, message: 'Please input your name!' }]}
            >
              <Input placeholder="Nome" />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Please input your email!' }]}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password placeholder="Senha" />
            </Form.Item>
            <Form.Item
              name="password_confirmation"
              rules={[{ required: true, message: 'Please confirm your password!' }]}
            >
              <Input.Password placeholder="Confirmar Senha" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading} block>
                Registrar
              </Button>
            </Form.Item>
            <Form.Item>
              <Button type="link" onClick={() => navigate('/login')} block>
                Já tem conta? Login
              </Button>
              <Button type="link" onClick={() => navigate('/resend-confirmation-email')} block>
                Não recebeu o e-mail de confirmação?
              </Button>
              <Button type="link" onClick={() => navigate('/request-password-reset')} block>
                Esqueceu a senha?
              </Button>

            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default RegisterForm;