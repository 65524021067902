import React, { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Table, Spin, Pagination, Input, Button, Modal } from 'antd';
import { GET_SCHEDULES, CREATE_SCHEDULE, UPDATE_SCHEDULE } from '../mutations/schedules';
import ScheduleForm from '../components/ScheduleForm';
import { format, parseISO } from 'date-fns';
const Schedules = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isFormModalVisible, setIsFormModalVisible] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [fetchSchedules, { loading, error, data }] = useLazyQuery(GET_SCHEDULES, {
    variables: { page: currentPage, perPage: pageSize, search: searchQuery },
  });

  const [createSchedule] = useMutation(CREATE_SCHEDULE);
  const [updateSchedule] = useMutation(UPDATE_SCHEDULE);
  const TRANSLATE_KEYS = {
    visit: 'Visita',
    prospect: 'Prospecção',
    call: 'Ligação',
    meeting: 'Reunião',
    task: 'Tarefa',
    email: 'E-mail',
    message: 'Mensagem',
    other: 'Outro',
  };
  

  useEffect(() => {
    fetchSchedules();
  }, [fetchSchedules]);

  const handleSearch = () => {
    setSearchQuery(searchTerm);
    setCurrentPage(1);
    fetchSchedules({
      variables: {
        page: 1,
        perPage: pageSize,
        search: searchTerm,
      },
    });
  };

  const handleCreateSchedule = () => {
    setSelectedSchedule(null);
    setIsEditMode(false);
    setIsFormModalVisible(true);
  };

  const handleEditSchedule = (schedule) => {
    setSelectedSchedule(schedule);
    setIsEditMode(true);
    setIsFormModalVisible(true);
  };

  const handleFormCancel = () => {
    setIsFormModalVisible(false);
    setSelectedSchedule(null);
    setErrorMessage(null);
  };

  const handleSave = async (values) => {
    setErrorMessage(null);
    try {
      let response;
      if (isEditMode) {
        response = await updateSchedule({
          variables: { id: selectedSchedule.id, attributes: values },
        });
      } else {
        response = await createSchedule({ variables: { attributes: values } });
      }

      const errors = response.data?.createSchedule?.errors || response.data?.updateSchedule?.errors;
      if (errors && errors.length > 0) {
        setErrorMessage(errors.join(', '));
      } else {
        fetchSchedules(); // Refetch schedules to update the list
        handleFormCancel(); // Close the modal
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(error.message || 'Ocorreu um erro inesperado.');
    }
  };

  if (loading) return <Spin />;
  if (error) return <p>Error: {error.message}</p>;

  const columns = [
    {
      title: 'Vendedor',
      dataIndex: ['seller', 'name'],
      key: 'seller',
    },
    {
      title: 'Cliente',
      dataIndex: ['customer', 'name'],
      key: 'customer',
    },
    {
      title: 'Tipo',
      dataIndex: 'kind',
      key: 'kind',
      render: (kind) => TRANSLATE_KEYS[kind] || kind, // Aplica a tradução ou exibe o valor original se não encontrado

    },
    {
      title: 'Início',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (text) => format(parseISO(text), 'dd/MM/yyyy - HH:mm'),
    },
    {
      title: 'Fim',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (text) => format(parseISO(text), 'dd/MM/yyyy - HH:mm'),
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Ações',
      key: 'actions',
      render: (text, record) => (
        <Button type="link" onClick={() => handleEditSchedule(record)}>
          Editar
        </Button>
      ),
    },
  ];

  const schedules = data?.schedules?.edges.map((edge) => edge.node) || [];
  const { totalCount } = data?.schedules || { totalCount: 0 };

  return (
    <>
      <div style={{ marginBottom: 16, display: 'flex', alignItems: 'center' }}>
        <Input
          placeholder="Pesquisar"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginRight: 8, width: 300 }}
          onPressEnter={handleSearch}
        />
        <Button type="primary" onClick={handleSearch}>
          Pesquisar
        </Button>
        <Button type="primary" style={{ marginLeft: 'auto' }} onClick={handleCreateSchedule}>
          Criar Agenda
        </Button>
      </div>

      <Table dataSource={schedules} columns={columns} rowKey="id" pagination={false} />

      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={totalCount}
        locale={{ items_per_page: '/ página' }}
        onChange={(page, pageSize) => {
          setCurrentPage(page);
          setPageSize(pageSize);
          fetchSchedules({
            variables: { page: page, perPage: pageSize, search: searchQuery },
          });
        }}
      />

      <Modal
        title={isEditMode ? 'Editar Agenda' : 'Criar Agenda'}
        open={isFormModalVisible}
        onCancel={handleFormCancel}
        footer={null}
        width={'79%'}
        maskClosable={false}
        getContainer={false}
      >
        <ScheduleForm
          initialValues={selectedSchedule}
          isEditMode={isEditMode}
          onFinish={handleSave}
          onCancel={handleFormCancel}
          errorMessage={errorMessage}
        />
      </Modal>
    </>
  );
};

export default Schedules;
