import React from 'react';
import { useQuery } from '@apollo/client';
import { PieChart, Pie, Tooltip, Cell, Legend } from 'recharts';
import { Spin, Alert } from 'antd';
import { GET_ORDERS_STATUS_BY_MONTH } from '../mutations/dashboard';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF4563', '#00CFFF', '#FF1234', '#123456', '#654321', '#FFD700'];

const OrderStatusChart = ({ month, year }) => {
  const { loading, error, data } = useQuery(GET_ORDERS_STATUS_BY_MONTH, {
    fetchPolicy: 'network-only',
    variables: { month, year },
  });

  if (loading) return <Spin />;
  if (error) return <Alert message="Error" type="error" description={error.message} />;

  const chartData = data.ordersStatusByMonth.map((statusData, index) => ({
    name: statusData.status,
    value: statusData.count,
    color: COLORS[index % COLORS.length],
  }));

  return (
    <PieChart width={500} height={300}>
      <Pie
        data={chartData}
        dataKey="value"
        nameKey="name"
        cx="50%"
        cy="50%"
        outerRadius={150}
        fill="#8884d8"
        label
      >
        {chartData.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))}
      </Pie>
      <Tooltip />
      <Legend layout="vertical"  align="left" verticalAlign="middle"/>
    </PieChart>
  );
};

export default OrderStatusChart;
