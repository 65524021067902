import React, { useState } from 'react';
import { Form, Input, Button, message, Row, Col, Card, Image } from 'antd';
import { useNavigate } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import client from '../apolloClient';

const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    login(input: {email: $email, password: $password}) {
      user {
        id
        email
      }
      authHeaders {
        accessToken
        client
        uid
      }
      errors
    }
  }
`;

const LoginForm = ({ onLogin }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [login] = useMutation(LOGIN_MUTATION);

  const handleLogin = async (values) => {
    setLoading(true);
    await client.resetStore();
    try {
      const { data } = await login({ variables: values });
      const { authHeaders, errors } = data.login;
      if (errors && errors.length > 0) {
        message.error(errors[0]);
        return;
      }

      localStorage.setItem('access-token', authHeaders.accessToken);
      localStorage.setItem('client', authHeaders.client);
      localStorage.setItem('uid', authHeaders.uid);

      message.success('Login realizado com sucesso');
      if (onLogin) {
        onLogin();
      } else {
        navigate('/dashboard');
      }
    } catch (error) {
      message.error('Não foi possível acessar');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row justify="center" align="middle" style={{ height: '100vh' }}>
      <Col xs={22} sm={16} md={12} lg={8} xl={6}>
        <Card>
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            <Image src={'/logo192.png'} alt="Logo" preview={false} width={400} />
            <h2>Login</h2>
          </div>
          <Form onFinish={handleLogin}>
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Por favor, preencha seu email!' }]}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Por favor, preencha sua senha!' }]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading} block>
                Acessar
              </Button>
            </Form.Item>
            <Form.Item>
              <Button type="link" onClick={() => navigate('/register')} block>
                Não possui conta? Registre-se
              </Button>
              <Button type="link" onClick={() => navigate('/request-password-reset')} block>
                Esqueceu a senha?
              </Button>
              <Button type="link" onClick={() => navigate('/resend-confirmation-email')} block>
                Não recebeu o e-mail de confirmação?
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default LoginForm;