import React, { useState, useEffect } from 'react';
import { useLazyQuery, useMutation, gql } from '@apollo/client';
import { Table, Spin, Pagination, Input, Button, Modal, Switch } from 'antd';
import { GET_OPERATIONS, UPDATE_OPERATION, CREATE_OPERATION, UPDATE_OPERATION_STATUS } from '../mutations/operations';
import OperationForm from '../components/OperationForm';

const Operations = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isFormModalVisible, setIsFormModalVisible] = useState(false);
  const [selectedOperation, setSelectedOperation] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [fetchOperations, { loading, error, data }] = useLazyQuery(GET_OPERATIONS, {
    variables: { page: currentPage, perPage: pageSize, search: searchQuery },
  });
  const [updateOperationStatus] = useMutation(UPDATE_OPERATION_STATUS);
  const [createOperation] = useMutation(CREATE_OPERATION, {
    update: (cache, { data: { createOperation } }) => {
      const { operation } = createOperation;
      if (!createOperation.operation) return;
      cache.modify({
        fields: {
          operations(existingOperations = { edges: [] }) {
            const newOperationRef = cache.writeFragment({
              data: operation,
              fragment: gql`
                fragment NewOperation on Operation {
                  id
                  name
                  active
                  default
                  requiresPayment
                }
              `
            });
            return {
              ...existingOperations,
              edges: [...existingOperations.edges, { node: newOperationRef }],
            };
          }
        }
      });
    },
  });

  const [updateOperation] = useMutation(UPDATE_OPERATION);

  useEffect(() => {
    fetchOperations();
  }, [fetchOperations]);

  const handleSearch = () => {
    setSearchQuery(searchTerm);
    setCurrentPage(1);
    fetchOperations({
      variables: {
        page: 1,
        perPage: pageSize,
        search: searchTerm,
      },
    });
  };

  const handleCreateOperation = () => {
    setSelectedOperation(null);
    setIsEditMode(false);
    setIsFormModalVisible(true);
  };

  const handleEditOperation = (operation) => {
    setSelectedOperation(operation);
    setIsEditMode(true);
    setIsFormModalVisible(true);
  };

  const handleFormCancel = () => {
    setIsFormModalVisible(false);
    setSelectedOperation(null);
    setErrorMessage(null);
  };

  const showConfirmModal = (operation) => {
    setSelectedOperation(operation);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    setIsModalVisible(false);
    const { id, active } = selectedOperation;
    try {
      await updateOperationStatus({
        variables: { id: id, active: !active },
        update: (cache, { data: { updateOperationStatus } }) => {
          const { operation } = updateOperationStatus;
          cache.modify({
            id: cache.identify(operation),
            fields: {
              active(cachedActive) {
                return !active;
              }
            }
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleSave = async (values) => {
    setErrorMessage(null);
    try {
      let response;
      if (isEditMode) {
        response = await updateOperation({
          variables: {
            id: selectedOperation.id,
            attributes: values,
          }
        });
      } else {
        response = await createOperation({
          variables: { attributes: values }
        });
      }

      const errors = response.data?.createOperation?.errors || response.data?.updateOperation?.errors;
      if (errors && errors.length > 0) {
        setErrorMessage(errors.join(', '));
      } else {
        fetchOperations(); // Refetch operations to update the list
        handleFormCancel(); // Close the modal
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(error.message || 'Ocorreu um erro inesperado.');
    }
  };

  if (loading) return <Spin />;
  if (error) return <p>Error: {error.message}</p>;

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },

    {
      title: 'Padrão',
      dataIndex: 'default',
      key: 'default',
      render: (defaultVal) => (defaultVal ? 'Sim' : 'Não'),
    },
    {
      title: 'Requer Pagamento',
      dataIndex: 'requiresPayment',
      key: 'requiresPayment',
      render: (requiresPayment) => (requiresPayment ? 'Sim' : 'Não'),
    },
    {
      title: 'Ativo',
      dataIndex: 'active',
      key: 'active',
      align: 'center',
      render: (active, record) => (
        <Switch
          checked={active}
          onChange={() => showConfirmModal(record)}
        />
      ),
    },
    {
      title: 'Ações',
      key: 'actions',
      render: (text, record) => (
        <Button type="link" onClick={() => handleEditOperation(record)}>Editar</Button>
      ),
    },
  ];

  const operations = data?.operations?.edges.map(edge => edge.node) || [];
  const { totalCount } = data?.operations || { totalCount: 0 };

  return (
    <>
      <div style={{ marginBottom: 16, display: 'flex', alignItems: 'center' }}>
        <Input
          placeholder="Pesquisar"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginRight: 8, width: 300 }}
          onPressEnter={handleSearch}
        />
        <Button type="primary" onClick={handleSearch}>Pesquisar</Button>
        <Button type="primary" style={{ marginLeft: 'auto' }} onClick={handleCreateOperation}>
          Criar Operação
        </Button>
      </div>
      <Table
        dataSource={operations}
        columns={columns}
        rowKey="id"
        pagination={false}
      />
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={totalCount}
        locale={{ itemsPerPage: '/ página' }}
        onChange={(page, pageSize) => {
          setCurrentPage(page);
          setPageSize(pageSize);
          fetchOperations({
            variables: {
              page: page,
              perPage: pageSize,
              search: searchQuery,
            },
          });
        }}
      />
      <Modal
        title={isEditMode ? "Editar Operação" : "Criar Operação"}
        open={isFormModalVisible}
        onCancel={handleFormCancel}
        footer={null}
        maskClosable={false}
        getContainer={false}
      >
        <OperationForm
          initialValues={selectedOperation}
          isEditMode={isEditMode}
          onFinish={handleSave}
          onCancel={handleFormCancel}
          errorMessage={errorMessage}
        />
      </Modal>
      <Modal
        title="Confirmar Alteração"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={() => setIsModalVisible(false)}
        okText="Confirmar"
        cancelText="Cancelar"
      >
        <p>Você realmente deseja {selectedOperation?.active ? 'desativar' : 'ativar'} esta operação?</p>
      </Modal>
    </>
  );
};

export default Operations;
