// src/pages/Orders.js
import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Table, Spin, Pagination, Input, Button, message, Modal, Checkbox, DatePicker, Form } from 'antd';
import { format, parseISO } from 'date-fns';
import { formatCurrency, formatPhoneNumber } from '../util';
import { GET_ORDERS, UPDATE_ORDER_STATUS } from '../mutations/orders';
import OrderStatusModal from '../components/OrderStatusModal';
import locale from 'antd/es/date-picker/locale/pt_BR';

const { RangePicker } = DatePicker;

const TRANSLATE_STATUS = {
  created: 'Criado',
  pending: 'Pendente',
  synchronized: 'Sincronizado',
  completed: 'Finalizado',
  declined: 'Rejeitado',
  shipped: 'Enviado',
  cancelled: 'Cancelado',
  billed: 'Faturado',
  failed: 'Falhou',
  draft: 'Rascunho',
  abandoned_cart: 'Pedido abandonado'
};

const STATUS_COLORS = {
  created: 'gray',
  pending: 'orange',
  synchronized: 'blue',
  completed: 'green',
  declined: 'red',
  shipped: 'purple',
  cancelled: 'black',
  billed: 'brown',
  failed: 'red',
  draft: 'yellow',
  abandoned_cart: 'orange'
};

const Orders = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [newStatus, setNewStatus] = useState("");
  const [situation, setSituation] = useState("");
  const [billingDate, setBillingDate] = useState(null);
  const [nfNumber, setNfNumber] = useState("");
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);

  const [fetchOrders, { loading, error, data }] = useLazyQuery(GET_ORDERS, {
   
    variables: { page: currentPage, perPage: pageSize, search: searchQuery},
  });

  const [updateOrderStatus] = useMutation(UPDATE_ORDER_STATUS);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  if (loading) return <Spin />;
  if (error) return <p>Error: {error.message}</p>;

  const handleSearch = () => {
    setSearchQuery(searchTerm);
    setCurrentPage(1);
    fetchOrders({
      fetchPolicy: 'network-only',
      variables: {
        page: 1,
        perPage: pageSize,
        search: searchTerm,
        statuses: selectedStatuses.length > 0 ? selectedStatuses : undefined,
        startDate: dateRange ? dateRange[0] : null,
        endDate: dateRange? dateRange[1] : null,
      },
    });
  };

  const handleFilter = () => {
    setFilterModalVisible(false);
    handleSearch();
  };

  const handleChangeStatus = async () => {
    try {
      await updateOrderStatus({
        variables: { 
          id: selectedOrder.id, 
          status: newStatus, 
          situation: situation, 
          billingDate: billingDate,
          nfNumber: nfNumber 
        },
        refetchQueries: [{ query: GET_ORDERS, variables: { page: currentPage, perPage: pageSize, search: searchQuery } }]
      });
      setModalVisible(false);
      message.success('Status atualizado com sucesso!');
    } catch (error) {
      message.error('Erro ao atualizar o status!');
    }
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (text) => <span style={{ color: STATUS_COLORS[text] }}>{TRANSLATE_STATUS[text]}</span>,
    },
    {
      title: 'Número',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Cliente',
      dataIndex: ['customer', 'name'],
      key: 'customer',
    },
    {
      title: 'Telefone',
      dataIndex: ['customer', 'phone'],
      key: 'phone',
      render: (phone) => formatPhoneNumber(phone),
    },
    {
      title: 'WhatsApp',
      dataIndex: ['customer', 'whatsapp'],
      key: 'whatsapp',
      render: (whatsapp) => formatPhoneNumber(whatsapp),
    },
    {
      title: 'Endereços',
      dataIndex: ['customer', 'addresses'],
      key: 'addresses',
      render: (addresses) => addresses.map((address) => address.fullAddress).join(', '),
    },
    {
      title: 'Criado em',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: (text) => format(parseISO(text), 'dd/MM/yyyy - HH:mm'),
    },
    {
      title: 'Data de Faturamento',
      dataIndex: 'billingDate',
      key: 'billingDate',
      align: 'center',
      render: (text) => text ? format(parseISO(text), 'dd/MM/yyyy') : '',
    },
    {
      title: 'Número da Nota',
      dataIndex: 'nfNumber',
      key: 'nfNumber',
      align: 'center',
    },
    {
      title: 'Desconto',
      dataIndex: 'discount',
      key: 'discount',
      render: (text) => formatCurrency(text),
      align: 'right'
    },
    {
      title: 'Acréscimo',
      dataIndex: 'addition',
      key: 'addition',
      render: (text) => formatCurrency(text),
      align: 'right'
    },
    {
      title: 'Total de produtos',
      dataIndex: 'totalProducts',
      key: 'totalProducts',
      render: (text) => formatCurrency(text),
      align: 'right'
    },
    {
      title: 'Total pago',
      dataIndex: 'total',
      key: 'total',
      render: (text) => formatCurrency(text),
      align: 'right'
    },
    {
      title: 'Ações',
      key: 'actions',
      fixed: 'right',
      render: (text, record) => (
        <Button type="primary" onClick={() => { 
          setSelectedOrder(record); 
          setModalVisible(true);
          setNewStatus('');
          setSituation('');
          setBillingDate(null);
          setNfNumber('');
        }}>
          Alterar Status
        </Button>
      ),
    }
  ];

  const expandedRowRender = (record) => {
    const columns = [
      { title: 'Ref', dataIndex: ['product', 'reference'], key: 'reference' },
      { title: 'Produto', dataIndex: ['product', 'name'], key: 'name' },
      { title: 'SKU', dataIndex: 'barcode', key: 'barcode' },
      { title: 'Quantidade', dataIndex: 'quantity', key: 'quantity', align: 'center' },
      { title: 'Desconto', dataIndex: 'discountPercent', key: 'discount', render: (text) => formatCurrency(text), align: 'right' },
      { title: 'Acréscimo', dataIndex: 'additionPercent', key: 'addition', render: (text) => formatCurrency(text), align: 'right' },
      {
        title: 'Valor',
        dataIndex: 'value',
        key: 'value',
        align: 'right',
        render: (text) => formatCurrency(text),
      },
      {
        title: 'Valor de venda',
        dataIndex: 'salesValue',
        key: 'salesValue',
        align: 'right',
        render: (text) => formatCurrency(text),
      },
      {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
        align: 'right',
        render: (text) => formatCurrency(text),
      },
    ];

    return <Table columns={columns} dataSource={record.orderProducts} pagination={false} rowKey="id" />;
  };

  const handleTableChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    fetchOrders({
      variables: {
        page: page,
        perPage: pageSize,
        search: searchQuery,
        statuses: selectedStatuses.length > 0 ? selectedStatuses : undefined,
        startDate: dateRange ? dateRange[0] : null,
        endDate: dateRange ? dateRange[1] : null,
      },
    });
  };

  const orders = data?.orders?.edges.map(edge => edge.node) || [];
  const { totalCount } = data?.orders || { pageInfo: {}, totalCount: 0 };
  return (
    <>
      <div style={{ marginBottom: 16, display: 'flex', alignItems: 'center' }}>
        <Input
          placeholder="Pesquisar"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginRight: 8, width: 300 }}
          onPressEnter={handleSearch}
          allowClear={true}
        />
        <Button type="primary" onClick={handleSearch}>Pesquisar</Button>
        <Button style={{ marginLeft: 8 }} onClick={() => setFilterModalVisible(true)}>Filtro</Button>
      </div>
      <Table
        dataSource={orders}
        columns={columns}
        rowKey="id"
        pagination={false}
        expandable={{ expandedRowRender }}
        scroll={{ x: 'max-content' }}
        onChange={handleTableChange}
      />
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={totalCount}
        locale={{ items_per_page: '/ página' }}
        onChange={handleTableChange}
      />
      <OrderStatusModal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={handleChangeStatus}
        newStatus={newStatus}
        setNewStatus={setNewStatus}
        situation={situation}
        setSituation={setSituation}
        billingDate={billingDate}
        setBillingDate={setBillingDate}
        nfNumber={nfNumber}
        setNfNumber={setNfNumber}
      />
      <Modal
        title="Filtrar Pedidos"
        open={filterModalVisible}
        onCancel={() => setFilterModalVisible(false)}
        onOk={handleFilter}
        width={600}
        maskClosable={false}
        getContainer={false}
        footer={[
          <Button key="cancel" onClick={() => setFilterModalVisible(false)} danger>
            Cancelar
          </Button>,
          <Button key="submit" type="primary" onClick={handleFilter}>
            Aplicar Filtro
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item label="Status">
            <Checkbox.Group
              options={Object.entries(TRANSLATE_STATUS).map(([key, value]) => ({ label: value, value: key }))}
              value={selectedStatuses}
              onChange={(checkedValues) => setSelectedStatuses(checkedValues)}
            />
          </Form.Item>
          <Form.Item label="Data">
            <RangePicker
              value={dateRange}
              onChange={(dates) => setDateRange(dates)}
              format="DD/MM/YYYY"
              locale={locale}
              placeholder={['Data inicial', 'Data final']}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Orders;
