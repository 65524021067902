import React, { useState, useEffect } from 'react';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Table, Spin, Pagination, Input, Button, Switch, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { CREATE_PRICE_SEGMENTATION, GET_PRICE_SEGMENTATIONS, UPDATE_PRICE_SEGMENTATION, UPDATE_PRICE_SEGMENTATION_STATUS } from '../mutations/priceSegmentations';
import PriceSegmentationForm from '../components/priceSegmentations/PriceSegmentationForm';
import { format } from 'date-fns';

const { confirm } = Modal;

const PriceSegmentations = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isFormModalVisible, setIsFormModalVisible] = useState(false);
  const [selectedSegmentation, setSelectedSegmentation] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [updatePriceSegmentation] = useMutation(UPDATE_PRICE_SEGMENTATION, {
    update: (cache, { data: { updatePriceSegmentation } }) => {
      if (updatePriceSegmentation?.priceSegmentation) {
        const { priceSegmentation } = updatePriceSegmentation;
        cache.modify({
          fields: {
            priceSegmentations(existingSegmentations = {}) {
              const updatedSegmentations = existingSegmentations.edges.map(edge => {
                if (edge.node.id === priceSegmentation.id) {
                  return { node: priceSegmentation };
                }
                return edge;
              });
              return {
                ...existingSegmentations,
                edges: updatedSegmentations,
              };
            },
          },
        });
      }
    },
  });

  const [createPriceSegmentation] = useMutation(CREATE_PRICE_SEGMENTATION, {
    update: (cache, { data: { createPriceSegmentation } }) => {
      if (createPriceSegmentation?.priceSegmentation) {
        const { priceSegmentation } = createPriceSegmentation;
        cache.modify({
          fields: {
            priceSegmentations(existingSegmentations = {}) {
              const newSegmentationRef = cache.writeFragment({
                data: priceSegmentation,
                fragment: gql`
                  fragment NewPriceSegmentation on PriceSegmentation {
                    id
                    name
                    promotional
                    hierarchy
                    initialDate
                    expirationDate
                    createdAt
                    updatedAt
                    active
                  }
                `,
              });
              return {
                ...existingSegmentations,
                edges: [...existingSegmentations.edges, { node: newSegmentationRef }],
              };
            },
          },
        });
      }
    },
  });

  const handleFinish = async (values) => {
    try {
      const mutation = isEditMode ? updatePriceSegmentation : createPriceSegmentation;
      const variables = isEditMode
        ? { id: selectedSegmentation.id, attributes: values }
        : { attributes: values };

      const { data } = await mutation({ variables });

      if ((data.createPriceSegmentation || data.updatePriceSegmentation).errors.length > 0) {
        setErrorMessage((data.createPriceSegmentation || data.updatePriceSegmentation).errors.join(', '));
      } else {
        handleFormCancel();
        fetchSegmentations();
      }
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const showConfirm = (id, active) => {
    confirm({
      title: `Você tem certeza que deseja ${active ? 'ativar' : 'desativar'} esta segmentação de preço?`,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        handleToggleActive(id, active);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const [updatePriceSegmentationStatus] = useMutation(UPDATE_PRICE_SEGMENTATION_STATUS);
  const handleToggleActive = async (id, active) => {
    try {
      await updatePriceSegmentationStatus({ variables: { id, active } });
      fetchSegmentations();
    } catch (error) {
      console.error('Error updating price segmentation status:', error);
    }
  };

  const [fetchSegmentations, { loading, error, data }] = useLazyQuery(GET_PRICE_SEGMENTATIONS, {
    variables: { page: currentPage, perPage: pageSize, search: searchQuery },
  });

  useEffect(() => {
    fetchSegmentations();
  }, [fetchSegmentations]);

  const handleSearch = () => {
    setSearchQuery(searchTerm);
    setCurrentPage(1);
    fetchSegmentations({
      variables: {
        page: 1,
        perPage: pageSize,
        search: searchTerm,
      },
    });
  };

  const handleCreateSegmentation = () => {
    setSelectedSegmentation(null);
    setIsEditMode(false);
    setIsFormModalVisible(true);
  };

  const handleEditSegmentation = (segmentation) => {
    setSelectedSegmentation(segmentation);
    setIsEditMode(true);
    setIsFormModalVisible(true);
  };

  const handleFormCancel = () => {
    setIsFormModalVisible(false);
    setSelectedSegmentation(null);
    setErrorMessage(null);
  };

  if (loading) return <Spin />;
  if (error) return <p>Error: {error.message}</p>;

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Hierarquia',
      dataIndex: 'hierarchy',
      key: 'hierarchy',
      align: 'center',
    },
    {
      title: 'Promocional',
      dataIndex: 'promotional',
      key: 'promotional',
      align: 'center',
      render: (promotional) => (promotional ? 'Sim' : 'Não'),
    },
    {
      title: 'Data Inicial',
      dataIndex: 'initialDate',
      key: 'initialDate',
      align: 'center',
      render: (date) => date ? format(new Date(date), 'dd/MM/yyyy') : 'N/A',
    },
    {
      title: 'Data de Expiração',
      dataIndex: 'expirationDate',
      key: 'expirationDate',
      align: 'center',
      render: (date) => date ? format(new Date(date), 'dd/MM/yyyy') : 'N/A',
    },
    {
      title: 'Ativo',
      dataIndex: 'active',
      key: 'active',
      align: 'center',
      render: (active, record) => (
        <Switch
          checked={active}
          onChange={() => showConfirm(record.id, !active)}
        />
      ),
    },
    {
      title: 'Ações',
      key: 'actions',
      render: (text, record) => (
        <Button type="link" onClick={() => handleEditSegmentation(record)}>Editar</Button>
      ),
    },
  ];

  const segmentations = data?.priceSegmentations?.edges.map(edge => edge.node) || [];
  const { totalCount } = data?.priceSegmentations || { pageInfo: {}, totalCount: 0 };

  return (
    <>
      <div style={{ marginBottom: 16, display: 'flex', alignItems: 'center' }}>
        <Input
          placeholder="Pesquisar"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginRight: 8, width: 300 }}
          onPressEnter={handleSearch}
        />
        <Button type="primary" onClick={handleSearch}>Pesquisar</Button>
        <Button type="primary" style={{ marginLeft: 'auto' }} onClick={handleCreateSegmentation}>
          Criar Segmentação de Preço
        </Button>
      </div>
      <Table
        dataSource={segmentations}
        columns={columns}
        rowKey="id"
        pagination={false}
      />
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={totalCount}
        locale={{ items_per_page: '/ página' }}
        onChange={(page, pageSize) => {
          setCurrentPage(page);
          setPageSize(pageSize);
          fetchSegmentations({
            variables: {
              page: page,
              perPage: pageSize,
              search: searchQuery,
            },
          });
        }}
      />
      <Modal
        title={isEditMode ? "Editar Segmentação de Preço" : "Criar Segmentação de Preço"}
        open={isFormModalVisible}
        onCancel={handleFormCancel}
        footer={null}
        maskClosable={false}
        getContainer={false}
        width={800}
      >
        <PriceSegmentationForm
          initialValues={selectedSegmentation}
          isEditMode={isEditMode}
          onFinish={handleFinish}
          onCancel={handleFormCancel}
          errorMessage={errorMessage}
        />
      </Modal>
    </>
  );
};

export default PriceSegmentations;
