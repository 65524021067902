// src/components/ProductImportModal.jsx
import React, { useState } from 'react';
import { Modal, Button, Upload, message, Tooltip, Row, Col } from 'antd';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { IMPORT_PRODUCTS } from '../mutations/importProducts';

const ProductImportModal = ({ visible, onCancel, refetchProducts }) => {
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [importProducts] = useMutation(IMPORT_PRODUCTS);

  const handleUpload = async () => {
    const file = fileList[0];
    if (!file) {
      message.error('Por favor, selecione um arquivo.');
      return;
    }

    setUploading(true);
    try {
      const { data } = await importProducts({
        variables: { file: file.originFileObj }
      });
      message.success(data.importProducts.message);
      if (refetchProducts) refetchProducts();
      
      onCancel();
    } catch (error) {
      message.error('Falha no upload.');
      console.error(error);
    } finally {
      setUploading(false);
    }
  };

  const handleFileChange = ({ fileList }) => {
    setFileList(fileList);
  };

  const downloadExampleFile = () => {
    const link = document.createElement('a');
    link.href = 'https://pedidosmoveis2.s3.amazonaws.com/products_example.xlsx'; // Path to your uploaded file
    link.download = 'products_example.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Modal
      open={visible}
      title="Importar Produtos"
      onCancel={onCancel}
      maskClosable={false}
      getContainer={false}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancelar
        </Button>,
        <Button key="submit" type="primary" onClick={handleUpload} disabled={fileList.length === 0} loading={uploading}>
          {uploading ? 'Enviando' : 'Enviar'}
        </Button>,
      ]}
    >
      <Row align="middle">
        <Col>
          <Upload
            fileList={fileList}
            onChange={handleFileChange}
            beforeUpload={() => false}
            accept=".xlsx"
          >
            <Button icon={<UploadOutlined />}>Selecione o arquivo</Button>
          </Upload>
        </Col>
        <Col>
          <Tooltip title="Baixe um exemplo de planilha de importação">
            <DownloadOutlined 
              onClick={downloadExampleFile} 
              style={{ marginLeft: 16, fontSize: '24px', color: '#ffc107', cursor: 'pointer' }}
            />
          </Tooltip>
        </Col>
      </Row>
    </Modal>
  );
};

export default ProductImportModal;
