import { gql } from '@apollo/client';

export const GET_PLACES_COUNT = gql`
  query placesCount($active: Boolean) {
    placesCount(active: $active) 
}`;

export const GET_ACTIVE_PLACES = gql`
  query GetActivePlaces {
    activePlaces {
      id
      internalId
      name
      corporateName
      document
    }
  }
`;

export const GET_PLACES = gql`
  query GetPlaces($page: Int, $perPage: Int, $search: String) {
    places(page: $page, perPage: $perPage, search: $search) {
      edges {
        node {
          id
          name
          corporateName
          phone
          internalId
          document
          email
          active
          createdAt
          logoUrl
          updatedAt
          address {
            street
            number
            room
            description
            district
            zipcode
            complement
            cityId
            city{
              id
              name
              state{
                id
                initials
              }
            }
          }
        }
      }
      totalCount
    }
  }
`;

export const CREATE_PLACE = gql`
  mutation CreatePlace($attributes: PlaceAttributes!) {
    createPlace(input: { attributes: $attributes }) {
      place {
        id
        name
        corporateName
        phone
        internalId
        email
        logoUrl
        address {
          street
          number
          room
          description
          district
          zipcode
          complement
          cityId
          city{
            id
            name
            state{
              id
              initials
            }
          }
        }
      }
      errors
    }
  }
`;

export const UPDATE_PLACE = gql`
  mutation UpdatePlace($id: ID!, $attributes: PlaceAttributes!) {
    updatePlace(input: { id: $id, attributes: $attributes }) {
      place {
        id
        name
        corporateName
        phone
        email
        internalId
        logoUrl
        address {
          street
          number
          room
          description
          district
          zipcode
          complement
          cityId
          city{
            id
            name
            state{
              id
              initials
            }
          }
        }
      }
      errors
    }
  }
`;

export const UPDATE_PLACE_STATUS = gql`
  mutation UpdatePlaceStatus($id: ID!, $active: Boolean!) {
    updatePlaceStatus(input: { id: $id, active: $active }) {
      place {
        id
        active
      }
      errors
    }
  }
`;

export const SEARCH_PLACES = gql`
  query SearchPlaces($query: String!) {
    searchPlaces(query: $query) {
      id
      internalId
      name
    }
  }
`;