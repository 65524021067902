import React from 'react';
import { InputNumber } from 'antd';

const CurrencyInput = (props) => {
  const { value, onChange, ...rest } = props;

  const handleChange = (value) => {
    if (value === undefined) {
      onChange({
        target: {
          name: props.name,
          value: '',
        },
      });
    } else {
      onChange({
        target: {
          name: props.name,
          value: value,
        },
      });
    }
  };

  return (
    <InputNumber
      value={value}
      onChange={handleChange}
      formatter={value => {
        if (value === undefined || value === '') {
          return '';
        }
        const [integer, decimal] = value.toString().split('.');
        const formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        return `R$ ${formattedInteger}${decimal ? ',' + decimal : ''}`;
      }}
      parser={value => {
        return value ? value.replace(/\R\$\s?|(\.*)/g, '').replace(',', '.') : '';
      }}
      {...rest}
      style={{ width: '100%' }}
    />
  );
};

export default CurrencyInput;