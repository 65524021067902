import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Alert, Upload, Divider, Row, Col } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import AddressForm from './AddressForm';

const PlaceForm = ({ initialValues, isEditMode, onFinish, onCancel, errorMessage }) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    
    if (initialValues) {
      let cityData = {};
      if (initialValues.address) {
        cityData = {
          value: initialValues?.address?.city?.id,
          label: `${initialValues?.address?.city?.name} - ${initialValues?.address?.city?.state?.initials}`
        };
      }
      form.setFieldsValue({
        ...initialValues,
        address: {
          ...initialValues.address,
          cityId: cityData
        }
      });
      if (initialValues.logoUrl) {
        setFileList([{ uid: '-1', name: 'image.png', status: 'done', url: initialValues.logoUrl }]);
      }
    } else {
      form.resetFields();
      setFileList([]);
    }

  }, [initialValues, form]);

  const handleChange = ({ fileList }) => setFileList(fileList);

  const handleSubmit = async (values) => {
    const { logoUrl, ...formattedValues } = values;
    const addressValues = {
      ...formattedValues.address,
      cityId: formattedValues.address.cityId?.value || formattedValues.address.cityId,
    };

    if (fileList.length > 0 && fileList[0].originFileObj) {
      formattedValues.logo = fileList[0].originFileObj;
    }
    const finalValues = {
      ...formattedValues,
      address: addressValues,
    };

    if (onFinish) onFinish(finalValues);
  };

  return (
    <Form
      form={form}
      initialValues={initialValues}
      onFinish={handleSubmit}
      layout="vertical"
    >
      {errorMessage && <Alert message={errorMessage} type="error" showIcon />}

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="name"
            label="Nome"
            rules={[{ required: true, message: 'Por favor insira o nome' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="corporateName"
            label="Fantasia"
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="document"
            label="Documento"
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={4}>
          <Form.Item
            name="internalId"
            label="Código"
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="phone"
            label="Telefone"
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="email"
            label="Email"
            rules={[{ type: 'email', message: 'Por favor insira um email válido' }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Divider>Endereço</Divider>
      <AddressForm initialValues={initialValues.address || {}} />

      <Row gutter={16}>
        <Col span={24}>
          <Form.Item name="logo" label="Imagem">
            <Upload
              listType="picture"
              fileList={fileList}
              beforeUpload={() => false} // Prevent automatic upload
              onChange={handleChange}
              maxCount={1} // Limita o upload para uma única imagem
            >
              <Button icon={<UploadOutlined />}>Selecionar Logo</Button>
            </Upload>
          </Form.Item>
        </Col>
      </Row>

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={onCancel} style={{ marginRight: 8, backgroundColor: 'red', color: 'white' }}>
          Cancelar
        </Button>
        <Button type="primary" htmlType="submit">
          {isEditMode ? 'Atualizar' : 'Criar'}
        </Button>
      </div>
    </Form>
  );
};

export default PlaceForm;
