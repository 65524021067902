import { gql } from "@apollo/client";

export const GET_PLUGCHAT_STATUS = gql`
  query GetPlugchatStatus {
    plugchatStatus {
      plugchatConnected
      plugchatQrCode
      connectedNumber
    }
  }
`;

export const DISCONNECT_PLUGCHAT = gql`
  mutation DisconnectPlugchat {
    disconnectPlugchat(input: {}) {
      success
    }
  }
`;

export const GET_ACCOUNT = gql`
  query GetAccount {
    account {
      id
      name
      phone
      domain
      internalId
      currentCode
      initialCode
      email
      document
      corporateName
      addressId
      uniqueDocument
      plugchatToken
      plugchatConnected
      plugchatQrCode
      searchLimit
      searchPartial
      anyWord
      abandonedCartTime
      attemptsLimit
      notifyOnBusinessHours
      connectedNumber
      address {
        street
        number
        room
        description
        district
        zipcode
        complement
        cityId
        city{
          id
          name
          state{
            id
            initials
          }
        }
      }
      
    }
  }
`;

export const UPDATE_ACCOUNT = gql`
  mutation StoreAccount($attributes: AccountAttributes!) {
    storeAccount(input: {attributes: $attributes}) {
      account {
        id
        name
        phone
        domain
        initialCode
        email
        document
        corporateName
        uniqueDocument
        plugchatToken
        currentCode
        searchLimit
        searchPartial
        anyWord
        abandonedCartTime
        attemptsLimit
        notifyOnBusinessHours
        address {
          id
          number
          street
          description
          room
          zipcode
          district
          complement
          cityId
          city{
            id
          }
        }
      }
      errors
    }
  }
`;
