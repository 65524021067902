import axios from 'axios';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
const apiUrl = process.env.REACT_APP_API_URL;

const useLogout = () => {
  const navigate = useNavigate();


  const logout = async () => {
    try {
      const token = localStorage.getItem('access-token');
      const client = localStorage.getItem('client');
      const uid = localStorage.getItem('uid');

      await axios.delete(`${apiUrl}/api/v1/auth/sign_out`, {
        headers: {
          'access-token': token,
          client: client,
          uid: uid,
        },
      });

      localStorage.removeItem('access-token');
      localStorage.removeItem('client');
      localStorage.removeItem('uid');

      message.success('Logout successful');
      
      navigate('/login');
    } catch (error) {
      message.error('Logout failed');
      console.error(error);
      if (error.response && error.response.status === 404) {
        // Remove tokens do local storage
        localStorage.removeItem('access-token');
        localStorage.removeItem('client');
        localStorage.removeItem('uid');
  
        // Redirecionar para a página de login
        navigate('/login');
      }
    }
  };

  return logout;
};

export default useLogout;