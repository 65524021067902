import React, { useState, useEffect } from 'react';
import { useLazyQuery, useMutation, gql } from '@apollo/client';
import { Table, Spin, Pagination, Input, Button, Modal, Switch } from 'antd';
import { format } from 'date-fns';
import { GET_GROUPS, UPDATE_GROUP_STATUS, CREATE_GROUP, UPDATE_GROUP } from '../mutations/groups';
import GroupForm from '../components/groups/GroupForm';

const Groups = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isFormModalVisible, setIsFormModalVisible] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [fetchGroups, { loading, error, data }] = useLazyQuery(GET_GROUPS, {
    variables: { page: currentPage, perPage: pageSize, search: searchQuery },
  });

  const [createGroup] = useMutation(CREATE_GROUP, {
    update: (cache, { data: { createGroup } }) => {
      const { group } = createGroup;
      if (!createGroup.group)
        return
      cache.modify({
        fields: {
          groups(existingGroups = { edges: [] }) {
            const newGroupRef = cache.writeFragment({
              data: group,
              fragment: gql`
                fragment NewGroup on Group {
                  id
                  name
                  description
                  active
                }
              `
            });
            return {
              ...existingGroups,
              edges: [...existingGroups.edges, { node: newGroupRef }],
            };
          }
        }
      });
    },
  });

  const [updateGroup] = useMutation(UPDATE_GROUP);
  const [updateGroupStatus] = useMutation(UPDATE_GROUP_STATUS);

  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  const handleSearch = () => {
    setSearchQuery(searchTerm);
    setCurrentPage(1);
    fetchGroups({
      variables: {
        page: 1,
        perPage: pageSize,
        search: searchTerm,
      },
    });
  };

  const handleCreateGroup = () => {
    setSelectedGroup(null);
    setIsEditMode(false);
    setIsFormModalVisible(true);
  };

  const handleEditGroup = (group) => {
    setSelectedGroup(group);
    setIsEditMode(true);
    setIsFormModalVisible(true);
  };

  const handleFormCancel = () => {
    setIsFormModalVisible(false);
    setSelectedGroup(null);
    setErrorMessage(null);
  };

  const handleSave = async (values) => {
    setErrorMessage(null);
    try {
      let response;
      if (isEditMode) {
        response = await updateGroup({
          variables: {
            id: selectedGroup.id,
            name: values.name,
            description: values.description || '',
            active: values.active !== undefined ? values.active : true,
          }
        });
      } else {
        response = await createGroup({
          variables: {
            name: values.name,
            description: values.description || '',
            active: values.active !== undefined ? values.active : true,
          }
        });
      }

      const errors = response.data?.createGroup?.errors || response.data?.updateGroup?.errors;
      if (errors && errors.length > 0) {
        setErrorMessage(errors.join(', '));
      } else {
        fetchGroups(); // Refetch groups to update the list
        handleFormCancel(); // Close the modal
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(error.message || 'Ocorreu um erro inesperado.');
    }
  };

  const showConfirmModal = (group) => {
    setSelectedGroup(group);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    setIsModalVisible(false);
    const { id, active } = selectedGroup;
    try {
      await updateGroupStatus({
        variables: { id, active: !active },
        update: (cache, { data: { updateGroupStatus } }) => {
          const { group } = updateGroupStatus;
          cache.modify({
            id: cache.identify(group),
            fields: {
              active(cachedActive) {
                return !active;
              }
            }
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  if (loading) return <Spin />;
  if (error) return <p>Error: {error.message}</p>;

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Criado em',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: (text) => format(new Date(text), 'dd/MM/yyyy - HH:mm'),
    },
    {
      title: 'Atualizado em',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text) => format(new Date(text), 'dd/MM/yyyy HH:mm'),
    },
    {
      title: 'Ativo',
      dataIndex: 'active',
      key: 'active',
      align: 'center',
      render: (active, record) => (
        <Switch
          checked={active}
          onChange={() => showConfirmModal(record)}
        />
      ),
    },
    {
      title: 'Ações',
      key: 'actions',
      render: (text, record) => (
        <Button type="link" onClick={() => handleEditGroup(record)}>Editar</Button>
      ),
    },
  ];

  const groups = data?.groups?.edges.map(edge => edge.node) || [];
  const { totalCount } = data?.groups || { pageInfo: {}, totalCount: 0 };

  return (
    <>
      <div style={{ marginBottom: 16, display: 'flex', alignItems: 'center' }}>
        <Input
          placeholder="Pesquisar"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginRight: 8, width: 300 }}
          onPressEnter={handleSearch}
        />
        <Button type="primary" onClick={handleSearch}>Pesquisar</Button>
        <Button type="primary" style={{ marginLeft: 'auto' }} onClick={handleCreateGroup}>
          Criar Grupo
        </Button>
      </div>
      <Table
        dataSource={groups}
        columns={columns}
        rowKey="id"
        pagination={false}
      />
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={totalCount}
        locale={{ items_per_page: '/ página' }}
        onChange={(page, pageSize) => {
          setCurrentPage(page);
          setPageSize(pageSize);
          fetchGroups({
            variables: {
              page: page,
              perPage: pageSize,
              search: searchQuery,
            },
          });
        }}
      />
      <Modal
        title={isEditMode ? "Editar Grupo" : "Criar Grupo"}
        open={isFormModalVisible}
        onCancel={handleFormCancel}
        footer={null}
        maskClosable={false}
        getContainer={false}
      >
        <GroupForm
          initialValues={selectedGroup}
          isEditMode={isEditMode}
          onFinish={handleSave}
          onCancel={handleFormCancel}
          errorMessage={errorMessage}
        />
      </Modal>
      <Modal
        title="Confirmação"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={() => setIsModalVisible(false)}
        maskClosable={false}
        getContainer={false}
      >
        <p>Tem certeza que deseja continuar?</p>
      </Modal>
    </>
  );
};

export default Groups;
