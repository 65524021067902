// src/App.js
import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import AppLayout from './components/Layout';
import Orders from './pages/Orders';
import Products from './pages/Products';
import Customers from './pages/Customers';
import LoginForm from './components/LoginForm';
import RegisterForm from './components/RegisterForm';
import AccountSettings from './components/AccountSettings';
import ConfirmEmail from './components/ConfirmEmail';
import SendConfirmationEmail from './components/SendConfirmationEmail';
import ResetPassword from './components/ResetPassword';
import RequestPasswordReset from './components/RequestPasswordReset';
import Groups from './pages/Groups';
import PaymentMethods from './pages/PaymentMethods';
import PaymentConditions from './pages/PaymentConditions';
import PaymentProfiles from './pages/PaymentProfiles';
import Dashboard from './pages/Dashboard';
import Sellers from './pages/Sellers';
import Links from './pages/Links';
import CheckinReasons from './pages/CheckinReasons';
import Places from './pages/Places';
import PriceSegmentations from './pages/PriceSegmentations';
import Operations from './pages/Operations';
import Schedules from './pages/schedules';

// Componente para proteger rotas
const PrivateRoute = ({ element: Component, ...rest }) => {
  const isAuthenticated = !!localStorage.getItem('access-token');
  return isAuthenticated ? <Component {...rest} /> : <Navigate to="/login" />;
};

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/resend-confirmation-email" element={<SendConfirmationEmail />} />
          <Route path="/confirm" element={<ConfirmEmail />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/register" element={<RegisterForm />} />
          <Route path="/request-password-reset" element={<RequestPasswordReset />} />
          <Route path="/reset_password" element={<ResetPassword />} />
          <Route path="/" element={<PrivateRoute element={AppLayout} />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="sellers" element={<Sellers />} />
            <Route path="account-settings" element={<AccountSettings />} />
            <Route path="orders" element={<Orders />} />
            <Route path="products" element={<Products />} />
            <Route path="operations" element={<Operations />} />
            <Route path="/paymentMethods" element={<PaymentMethods />} />
            <Route path="/paymentConditions" element={<PaymentConditions />} />
            <Route path="customers" element={<Customers />} />
            <Route path="paymentProfiles" element={<PaymentProfiles />} />
            <Route path="groups" element={<Groups />} />
            <Route path="links" element={<Links />} />
            <Route path="checkinReasons" element={<CheckinReasons />} />
            <Route path="places" element={<Places />} />
            <Route path="priceSegmentations" element={<PriceSegmentations />} />
            <Route path="schedules" element={<Schedules />} />
            <Route path="/" element={<Navigate to="/dashboard" />} />
          </Route>
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
