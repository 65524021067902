import React, { useState, useEffect } from 'react';
import { useLazyQuery, useMutation, gql } from '@apollo/client';
import { Table, Spin, Pagination, Input, Button, Modal } from 'antd';
import { format } from 'date-fns';
import { GET_LINKS, UPDATE_LINK, CREATE_LINK } from '../mutations/links';
import LinkForm from '../components/LinkForms';

const Links = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isFormModalVisible, setIsFormModalVisible] = useState(false);
  const [selectedLink, setSelectedLink] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [fetchLinks, { loading, error, data }] = useLazyQuery(GET_LINKS, {
    variables: { page: currentPage, perPage: pageSize, search: searchQuery },
  });

  const [createLink] = useMutation(CREATE_LINK, {
    update: (cache, { data: { createLink } }) => {
      const { link } = createLink;
      if (!createLink.link) return;
      cache.modify({
        fields: {
          links(existingLinks = { edges: [] }) {
            const newLinkRef = cache.writeFragment({
              data: link,
              fragment: gql`
                fragment NewLink on Link {
                  id
                  name
                  url
                }
              `
            });
            return {
              ...existingLinks,
              edges: [...existingLinks.edges, { node: newLinkRef }],
            };
          }
        }
      });
    },
  });

  const [updateLink] = useMutation(UPDATE_LINK);

  useEffect(() => {
    fetchLinks();
  }, [fetchLinks]);

  const handleSearch = () => {
    setSearchQuery(searchTerm);
    setCurrentPage(1);
    fetchLinks({
      variables: {
        page: 1,
        perPage: pageSize,
        search: searchTerm,
      },
    });
  };

  const handleCreateLink = () => {
    setSelectedLink(null);
    setIsEditMode(false);
    setIsFormModalVisible(true);
  };

  const handleEditLink = (link) => {
    setSelectedLink(link);
    setIsEditMode(true);
    setIsFormModalVisible(true);
  };

  const handleFormCancel = () => {
    setIsFormModalVisible(false);
    setSelectedLink(null);
    setErrorMessage(null);
  };

  const handleSave = async (values) => {
    setErrorMessage(null);
    try {
      let response;
      if (isEditMode) {
        response = await updateLink({
          variables: {
            id: selectedLink.id,
            name: values.name,
            url: values.url,
          }
        });
      } else {
        response = await createLink({
          variables: {
            name: values.name,
            url: values.url,
          }
        });
      }

      const errors = response.data?.createLink?.errors || response.data?.updateLink?.errors;
      if (errors && errors.length > 0) {
        setErrorMessage(errors.join(', '));
      } else {
        fetchLinks(); // Refetch links to update the list
        handleFormCancel(); // Close the modal
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(error.message || 'Ocorreu um erro inesperado.');
    }
  };

  if (loading) return <Spin />;
  if (error) return <p>Error: {error.message}</p>;

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url',
    },
    {
      title: 'Criado em',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: (text) => format(new Date(text), 'dd/MM/yyyy - HH:mm'),
    },
    {
      title: 'Atualizado em',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text) => format(new Date(text), 'dd/MM/yyyy HH:mm'),
    },
    {
      title: 'Ações',
      key: 'actions',
      render: (text, record) => (
        <Button type="link" onClick={() => handleEditLink(record)}>Editar</Button>
      ),
    },
  ];

  const links = data?.links?.edges.map(edge => edge.node) || [];
  const { totalCount } = data?.links || { totalCount: 0 };

  return (
    <>
      <div style={{ marginBottom: 16, display: 'flex', alignItems: 'center' }}>
        <Input
          placeholder="Pesquisar"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginRight: 8, width: 300 }}
          onPressEnter={handleSearch}
        />
        <Button type="primary" onClick={handleSearch}>Pesquisar</Button>
        <Button type="primary" style={{ marginLeft: 'auto' }} onClick={handleCreateLink}>
          Criar Link
        </Button>
      </div>
      <Table
        dataSource={links}
        columns={columns}
        rowKey="id"
        pagination={false}
      />
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={totalCount}
        locale={{ items_per_page: '/ página' }}
        onChange={(page, pageSize) => {
          setCurrentPage(page);
          setPageSize(pageSize);
          fetchLinks({
            variables: {
              page: page,
              perPage: pageSize,
              search: searchQuery,
            },
          });
        }}
      />
      <Modal
        title={isEditMode ? "Editar Link" : "Criar Link"}
        open={isFormModalVisible}
        onCancel={handleFormCancel}
        footer={null}
        maskClosable={false}
        getContainer={false}
      >
        <LinkForm
          initialValues={selectedLink}
          isEditMode={isEditMode}
          onFinish={handleSave}
          onCancel={handleFormCancel}
          errorMessage={errorMessage}
        />
      </Modal>
    </>
  );
};

export default Links;
