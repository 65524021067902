// src/utils.js
import { parsePhoneNumber } from 'libphonenumber-js';

export const formatCurrency = (value) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);
};

export const formatPhoneNumber = (phone) => {
  try {
    const phoneNumber = parsePhoneNumber(phone, 'BR');
    return phoneNumber.formatInternational();
  } catch (error) {
    return phone; // Retorna o telefone original se houver um erro de formatação
  }
};