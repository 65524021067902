// src/components/CustomerForm.jsx
import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col, Switch, Divider, Select } from 'antd';
import { useLazyQuery, useQuery } from '@apollo/client';
import { SEARCH_CITIES } from '../../mutations/address';
import { SEARCH_PAYMENT_PROFILES, GET_ACTIVE_PAYMENT_PROFILES } from '../../mutations/paymentProfiles';
import useDebounce from '../../hooks/useDebounce';
import { CloseCircleOutlined } from '@ant-design/icons';
import './CustomerForm.css';
import { GET_CUSTOMER_BY_ID } from '../../mutations/customers';
import { SEARCH_SELLERS } from '../../mutations/sellers';
import { GET_ACTIVE_PRICE_SEGMENTATIONS } from '../../mutations/priceSegmentations';

const CustomerForm = ({ initialValues = {}, onFinish, onCancel, errorMessage, isEditMode = false }) => {
  const [form] = Form.useForm();
  const [getCustomerById] = useLazyQuery(GET_CUSTOMER_BY_ID);
  const [loadPaymentProfile, { loading: loadingPaymentProfile }] = useLazyQuery(GET_ACTIVE_PAYMENT_PROFILES, { fetchPolicy: 'network-only' });
  const [priceSegmentations, setPriceSegmentations] = useState([]);
  const [paymentProfiles, setPaymentProfiles] = useState([]);
  const [priceSegmentationIds, setPriceSegmentationIds] = useState([]);
  const [paymentProfileId, setPaymentProfileId] = useState([]);
  const { loading, data } = useQuery(GET_ACTIVE_PRICE_SEGMENTATIONS);



  useEffect(() => {
    if (data && data.activePriceSegmentations) {
      setPriceSegmentations(data.activePriceSegmentations);

    }
  }, [data]);

  useEffect(() => {
    loadPaymentProfile().then(result => {
      if (result && result.data?.activePaymentProfiles) {
        setPaymentProfiles(result.data.activePaymentProfiles)
      }
    })
  }, [loadPaymentProfile]);

  const initialEmails = initialValues.emails || [{ email: '', description: '', active: true }];
  const initialAddresses = initialValues.addresses || [{
    zipcode: '',
    cityId: '',
    description: '',
    district: '',
    street: '',
    number: '',
    complement: '',
    main: false,
  }];
  const initialSellers = initialValues.sellers || [];

  const [searchCities, { data: citiesData }] = useLazyQuery(SEARCH_CITIES);
  const [searchPaymentProfiles, { data: paymentProfilesData }] = useLazyQuery(SEARCH_PAYMENT_PROFILES);
  const [searchSellers, { data: sellersData }] = useLazyQuery(SEARCH_SELLERS);
  const [cities, setCities] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [allowAllSellers, setAllowAllSellers] = useState(initialValues.allowAllSellers || false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500); // 500ms delay

  useEffect(() => {
    if (debouncedSearchTerm.length > 2) {
      searchCities({ variables: { query: debouncedSearchTerm } });
      searchPaymentProfiles({ variables: { query: debouncedSearchTerm } });
      searchSellers({ variables: { query: debouncedSearchTerm } });
    }
  }, [debouncedSearchTerm, searchCities, searchPaymentProfiles, searchSellers]);



  useEffect(() => {
    if (citiesData) {
      setCities(citiesData.searchCities);
    }
    if (paymentProfilesData) {
      setPaymentProfiles(paymentProfilesData.searchPaymentProfiles);
    }
    if (sellersData) {
      setSellers(sellersData.searchSellers);
    }
  }, [citiesData, paymentProfilesData, sellersData]);

  useEffect(() => {
    form.setFieldValue('allowAllSellers', allowAllSellers);
  }, [allowAllSellers, form]);

  const handleSearchCity = (value) => {
    setSearchTerm(value);
  };

  const handleSearchPaymentProfile = (value) => {
    setSearchTerm(value);
  };

  const handleSearchSeller = (value) => {
    setSearchTerm(value);
  };

  const handleAllowAllSellersChange = (checked) => {
    setAllowAllSellers(checked);
  };

  useEffect(() => {
    form.resetFields();
    if (isEditMode) {
      getCustomerById({ variables: { id: initialValues.id } }).then((response) => {

        const customer = response?.data?.customer;
        setPaymentProfileId(customer.paymentProdile?.id)
        if (customer) {
          const addressesAttributes = customer.addresses.map(address => ({
            ...address,
            cityId: { value: address?.city?.id, label: `${address?.city?.name} - ${address?.city?.state?.initials}` }
          }));
          const emailsAttributes = customer.emails.map(email => ({
            ...email,
          }));
          form.setFieldsValue(
            {
              ...customer,
              addressesAttributes,
              emailsAttributes,
              paymentProfileId: customer.paymentProfile?.id,
              priceSegmentationIds: customer.priceSegmentationIds || [],
            });
        } else {
          form.resetFields();
        }
      });
    } else {
      form.resetFields();
    }
  }, [initialValues, isEditMode, form, getCustomerById]);

  useEffect(() => {
    if (initialValues.city) {
      setCities([{ id: initialValues.city.id, name: initialValues.city.name, state: initialValues.city.state }]);
    }
  }, [initialValues]);

  const handleSubmit = async (values) => {
    setPriceSegmentationIds([])
    if (onFinish) onFinish(values)
  }

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={{ ...initialValues, emails: initialEmails, addresses: initialAddresses, sellers: initialSellers, allowAllSellers }}
    >
      <Form.Item
        name={'platform'}
        key={'platform'}
        hidden={true}
      >
        <Input value={'pm'} />
      </Form.Item>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="name"
            label="Razão Social"
            rules={[{ required: true, message: 'Por favor, insira a razão social ou nome' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="nickname" label="Nome Fantasia">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="document"
            label="Documento"
            rules={[{ required: true, message: 'Por favor, insira o documento' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="stateRegistration" label="Inscrição Estadual">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="phone"
            label="Telefone Principal"
            rules={[{ required: true, message: 'Por favor, insira o telefone principal' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="phoneExtra" label="Telefone Secundário">
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="whatsapp" label="WhatsApp">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item name="observation" label="Observação">
            <Input.TextArea />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="priceSegmentationIds" label="Tabelas de preço" >
            <Select
              placeholder="Selecione as tabelas depreço"
              mode="multiple"  // Permite seleção de múltiplos places
              loading={loading}
              defaultValue={priceSegmentationIds}
            >
              {priceSegmentations.map(price => (
                <Select.Option key={price.id} value={price.id}>
                  {price.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="paymentProfileId"
            label="Perfil de Pagamento"
          >
            <Select

              loading={loadingPaymentProfile}
              placeholder="Pesquisar perfil de pagamento"
              onSearch={handleSearchPaymentProfile}
              defaultValue={paymentProfileId}
            >
              {paymentProfiles.map(paymentProfile => (
                <Select.Option key={paymentProfile.id} value={paymentProfile.id}>
                  {paymentProfile.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Divider orientation="left" orientationMargin="0" plain>
        Emails
      </Divider>
      <Form.List name="emailsAttributes" initialValue={initialEmails}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <Row gutter={16} key={key}>
                <Col span={10}>
                  <Form.Item
                    {...restField}
                    name={[name, 'email']}
                    key={[fieldKey, 'email']}
                    label="Email"
                    rules={[{ required: true, message: 'Por favor, insira o email' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    {...restField}
                    name={[name, 'description']}
                    key={[fieldKey, 'description']}
                    label="Descrição"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    {...restField}
                    name={[name, 'active']}
                    key={[fieldKey, 'active']}
                    valuePropName="checked"
                    label="Ativo"
                  >
                    <Switch />
                  </Form.Item>
                </Col>
                <Col style={{ textAlign: 'center' }}>
                  <Button
                    type="text"
                    className='remove-email'
                    icon={<CloseCircleOutlined style={{ color: 'red' }} />}
                    onClick={() => remove(name)}
                  />
                </Col>
              </Row>
            ))}
            <Button type="dashed" onClick={() => add()} block>
              Adicionar Email
            </Button>
          </>
        )}
      </Form.List>

      <Divider orientation="left" orientationMargin="0" plain>
        Endereços
      </Divider>
      <Form.List name="addressesAttributes" initialValue={initialAddresses}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <div key={key} style={{ marginBottom: 24 }}>
                <Row gutter={16}>
                  <Col span={22}>
                    <Row gutter={16}>
                      <Col span={5}>
                        <Form.Item
                          {...restField}
                          name={[name, 'zipcode']}
                          key={[key, 'zipcode']}
                          label="CEP"
                          rules={[{ required: true, message: 'Por favor, insira o CEP' }]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={10}>
                        <Form.Item
                          {...restField}
                          name={[name, 'cityId']}
                          key={[key, 'cityId']}
                          label="Cidade"
                          rules={[{ required: true, message: 'Por favor, selecione a cidade' }]}
                        >
                          <Select
                            showSearch
                            placeholder="Pesquisar cidade"
                            onSearch={handleSearchCity}
                            filterOption={false}
                            options={cities.map(city => ({ value: city.id, label: `${city.name} - ${city?.state?.initials}` }))}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          {...restField}
                          name={[name, 'district']}
                          key={[key, 'district']}
                          label="Bairro"
                          rules={[{ required: true, message: 'Por favor, insira o bairro' }]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={8}>
                        <Form.Item
                          {...restField}
                          name={[name, 'street']}
                          key={[key, 'street']}
                          label="Rua"
                          rules={[{ required: true, message: 'Por favor, insira a rua' }]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item
                          {...restField}
                          name={[name, 'number']}
                          key={[key, 'number']}
                          label="Número"
                          rules={[{ required: true, message: 'Por favor, insira o número' }]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          {...restField}
                          name={[name, 'description']}
                          key={[key, 'description']}
                          label="Descrição"
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item
                          {...restField}
                          name={[name, 'main']}
                          key={[key, 'main']}
                          label="Principal"
                          valuePropName="checked"
                        >
                          <Switch />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={24}>
                        <Form.Item
                          {...restField}
                          name={[name, 'complement']}
                          key={[key, 'complement']}
                          label="Complemento"
                        >
                          <Input.TextArea allowClear={true} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col style={{ textAlign: 'center', marginTop: '90px' }} span={2}>
                    <Button
                      type="text"
                      className='remove-email'
                      icon={<CloseCircleOutlined style={{ color: 'red' }} />}
                      onClick={() => remove(name)}
                    />
                  </Col>
                </Row>
              </div>
            ))}
            <Button type="dashed" onClick={() => add()} block>
              Adicionar Endereço
            </Button>
          </>
        )}
      </Form.List>

      <Divider orientation="left" orientationMargin="0" plain>
        Vendedores
      </Divider>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item name="allowAllSellers" valuePropName="checked">
            <Switch onChange={handleAllowAllSellersChange} checked={allowAllSellers} />
            <label style={{ marginLeft: 8 }}>Permitir todos os vendedores</label>
          </Form.Item>
        </Col>
      </Row>
      {!allowAllSellers && (
        <Form.List name="sellers" initialValue={initialSellers}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Row gutter={16} key={key}>
                  <Col span={22}>
                    <Form.Item
                      {...restField}
                      name={[name, 'id']}
                      key={[key, 'id']}
                      label="Vendedor"
                      rules={[{ required: true, message: 'Por favor, selecione o vendedor' }]}
                    >
                      <Select
                        showSearch
                        placeholder="Pesquisar vendedor"
                        onSearch={handleSearchSeller}
                        filterOption={false}
                        options={sellers.map(seller => ({ value: seller.id, label: seller.name }))}
                      />
                    </Form.Item>
                  </Col>
                  <Col style={{ textAlign: 'center' }} span={2}>
                    <Button
                      type="text"
                      className='remove-seller'
                      icon={<CloseCircleOutlined style={{ color: 'red' }} />}
                      onClick={() => remove(name)}
                    />
                  </Col>
                </Row>
              ))}
              <Button type="dashed" onClick={() => add()} block>
                Adicionar Vendedor
              </Button>
            </>
          )}
        </Form.List>
      )}

      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
        <Button onClick={onCancel} style={{ marginRight: 8, backgroundColor: 'red', color: 'white' }}>
          Cancelar
        </Button>
        <Button type="primary" htmlType="submit">
          {isEditMode ? "Atualizar" : "Criar"}
        </Button>
      </div>
    </Form>
  );
};

export default CustomerForm;
