import React, { useEffect } from 'react';
import { Form, Input, Button, Alert, Switch, Row, Col } from 'antd';

const OperationForm = ({ initialValues, isEditMode, onFinish, onCancel, errorMessage }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    } else {
      form.resetFields();
    }
  }, [initialValues, form]);

  const handleSubmit = async (values) => {
    if (onFinish) onFinish(values);
  };

  return (
    <Form
      form={form}
      initialValues={initialValues}
      onFinish={handleSubmit}
      layout="vertical"
    >
      {errorMessage && <Alert message={errorMessage} type="error" showIcon />}

      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="name"
            label="Nome"
            rules={[{ required: true, message: 'Por favor insira o nome' }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={7}>
          <Form.Item
            name="active"
            label="Ativo"
            valuePropName="checked" // Força o valor booleano para o Switch
          >
            <Switch checkedChildren="Sim" unCheckedChildren="Não" />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
            name="default"
            label="Padrão"
            valuePropName="checked"
          >
            <Switch checkedChildren="Sim" unCheckedChildren="Não" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="requiresPayment"
            label="Requer Pagamento"
            valuePropName="checked"
          >
            <Switch checkedChildren="Sim" unCheckedChildren="Não" />
          </Form.Item>
        </Col>
      </Row>

      

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={onCancel} style={{ marginRight: 8, backgroundColor: 'red', color: 'white' }}>
          Cancelar
        </Button>
        <Button type="primary" htmlType="submit">
          {isEditMode ? 'Atualizar' : 'Criar'}
        </Button>
      </div>
    </Form>
  );
};

export default OperationForm;
