import { gql } from "@apollo/client";

export const SALES_SUMMARY = gql`
  query GetSalesSummary {
    salesSummary {
      totalSalesMonth
      totalOnMonth

    }
  }
`;

export const GET_ORDERS_STATUS_BY_MONTH = gql`
  query GetOrdersStatusByMonth($month: Int!, $year: Int!) {
    ordersStatusByMonth(month: $month, year: $year) {
      status
      count
    }
  }
`;

export const GET_TOP_SELLING_PRODUCTS = gql`
  query GetTopSellingProducts {
    topSellingProducts {
      name
      totalQuantity
    }
  }
`;

export const GET_TOP_SELLING_PRODUCTS_BY_MONTH = gql`
  query GetTopSellingProductsByMonth($month: Int!, $year: Int!) {
    topSellingProductsByMonth(month: $month, year: $year) {
      name
      totalQuantity
    }
  }
`;