import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Button, Col, Row, Switch, InputNumber, Divider, Select } from 'antd';
import { GET_ACTIVE_PLACES } from '../mutations/places';
import { useQuery } from '@apollo/client';

const SellerModalForm = ({ visible, onCancel, initialValues, isEditMode, onFinish }) => {
  const [form] = Form.useForm();
  const [places, setPlaces] = useState([]);
  const [placeIds, setPlaceIds] = useState([]);
  const { loading, data } = useQuery(GET_ACTIVE_PLACES);



  useEffect(() => {
    if (data && data.activePlaces) {
      setPlaces(data.activePlaces);
      
    }
  }, [data]);


  const resetFields = () => {
    form.setFieldsValue({
      name: '',
      email: '',
      active: true,
      placeIds: [],
      viewAllCustomers: false,
      canSellDefaulterClients: false,
      maximumDiscountPercentage: 0,
      canEditProductValue: false,
      canSellBelowMinimumValue: false,
      maximumDiscountPercentagePerProduct: 0,
      minimumProfitMargin: 0,
      minimumOrderProfitMargin: 0,
      canSellBelowMinimumProfitMargin: false,
      canSellBelowMinimumOrderProfitMargin: false,
      viewProfitValue: false,
      canSellWithoutStock: false,
      canChangePriceSegmentation: false,
      canUpdateCustomer: false,
      canViewMinimumValue: false,
      canInsertCustomer: false,
      canViewStock: false,
      checkinOnOrder: false,
      canSellInCash: false,
      canDeleteSchedule: false,
    });
  };

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue({
        ...initialValues,
        ...initialValues.permissions,
        placeIds: initialValues.placeIds || [],
      });
    } else {
      form.setFieldsValue({
        name: '',
        email: '',
        active: true,
        placeIds: [],
        viewAllCustomers: false,
        canSellDefaulterClients: false,
        maximumDiscountPercentage: 0,
        canEditProductValue: false,
        canSellBelowMinimumValue: false,
        maximumDiscountPercentagePerProduct: 0,
        minimumProfitMargin: 0,
        minimumOrderProfitMargin: 0,
        canSellBelowMinimumProfitMargin: false,
        canSellBelowMinimumOrderProfitMargin: false,
        viewProfitValue: false,
        canSellWithoutStock: false,
        canChangePriceSegmentation: false,
        canUpdateCustomer: false,
        canViewMinimumValue: false,
        canInsertCustomer: false,
        canViewStock: false,
        checkinOnOrder: false,
        canSellInCash: false,
        canDeleteSchedule: false,
      });
    }
  }, [initialValues, isEditMode, form, visible, placeIds]);

  const handleSubmit = async (values) => {
    const permissions = {
      viewAllCustomers: values.viewAllCustomers,
      canSellDefaulterClients: values.canSellDefaulterClients,
      maximumDiscountPercentage: values.maximumDiscountPercentage,
      canEditProductValue: values.canEditProductValue,
      canSellBelowMinimumValue: values.canSellBelowMinimumValue,
      maximumDiscountPercentagePerProduct: values.maximumDiscountPercentagePerProduct,
      minimumProfitMargin: values.minimumProfitMargin,
      minimumOrderProfitMargin: values.minimumOrderProfitMargin,
      canSellBelowMinimumProfitMargin: values.canSellBelowMinimumProfitMargin,
      canSellBelowMinimumOrderProfitMargin: values.canSellBelowMinimumOrderProfitMargin,
      viewProfitValue: values.viewProfitValue,
      canSellWithoutStock: values.canSellWithoutStock,
      canChangePriceSegmentation: values.canChangePriceSegmentation,
      canUpdateCustomer: values.canUpdateCustomer,
      canViewMinimumValue: values.canViewMinimumValue,
      canInsertCustomer: values.canInsertCustomer,
      canViewStock: values.canViewStock,
      checkinOnOrder: values.checkinOnOrder,
      canSellInCash: values.canSellInCash,
      canDeleteSchedule: values.canDeleteSchedule,
    };

    const formattedValues = {
      name: values.name,
      email: values.email,
      active: values.active,
      permissions,
      placeIds: values.placeIds,
    };
    setPlaceIds([])
    onFinish(formattedValues);
  };

  const handleCancel = () => {
    resetFields();
    form.setFieldsValue({});
    onCancel();
  };

  // Add code to lead places to seller

  return (
    <Modal
      title={isEditMode ? "Editar Vendedor" : "Criar Vendedor"}
      open={visible}
      onCancel={handleCancel}
      onClose={handleCancel}
      footer={null}
      maskClosable={false}
      getContainer={false}
      width={1200}
    >
      <Form
        form={form}
        initialValues={initialValues}
        onFinish={handleSubmit}
        className="custom-form"
        layout="vertical"
        style={{ width: '100%' }}
      >
        <Row gutter={16}>
          <Col span={10}>
            <Form.Item name="name" key='name' label="Nome" rules={[{ required: true, message: 'Por favor insira o nome' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Por favor insira o email' }]}>
              <Input type="email" />
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item name="active" label="Ativo" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="placeIds" label="Empresas" rules={[{ required: true, message: 'Por favor selecione uma ou mais empresas' }]}>
              <Select
                placeholder="Selecione as empresas"
                mode="multiple"  // Permite seleção de múltiplos places
                loading={loading}
                defaultValue={placeIds}
              >
                {places.map(place => (
                  <Select.Option key={place.id} value={place.internalId}>
                    {place.internalId + ' - ' + place.name + ' - ' + place.document}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Divider>Permissões</Divider>
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item name="maximumDiscountPercentage" label="% Máximo de Desconto">
              <InputNumber min={0} max={100} style={{ width: '80%' }} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="maximumDiscountPercentagePerProduct" label="% Máximo de Desconto por Produto">
              <InputNumber min={0} max={100} style={{ width: '80%' }} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="minimumProfitMargin" label="Margem de Lucro Mínima">
              <InputNumber min={0} style={{ width: '80%' }} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="minimumOrderProfitMargin" label="Margem de Lucro Mínima por Pedido">
              <InputNumber min={0} style={{ width: '80%' }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="viewAllCustomers" label="Visualizar Todos Clientes" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="canSellDefaulterClients" label="Pode Vender para Inadimplentes" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="canSellInCash" label="Pode Vender à Vista" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="canEditProductValue" label="Pode Editar Valor do Produto" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="canSellBelowMinimumValue" label="Pode Vender Abaixo do Valor Mínimo" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="canDeleteSchedule" label="Pode Excluir Agendamento" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="canSellBelowMinimumProfitMargin" label="Pode Vender Abaixo da Margem de Lucro Mínima" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="canSellBelowMinimumOrderProfitMargin" label="Pode Vender Abaixo da Margem de Lucro Mínima por Pedido" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="viewProfitValue" label="Visualizar Valor de Lucro" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="canSellWithoutStock" label="Pode Vender Sem Estoque" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="canChangePriceSegmentation" label="Pode Alterar Segmentação de Preço" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="checkinOnOrder" label="Check-in no Pedido" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="canUpdateCustomer" label="Pode Atualizar Cliente" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="canViewMinimumValue" label="Pode Visualizar Valor Mínimo" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="canInsertCustomer" label="Pode Inserir Cliente" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="canViewStock" label="Pode Visualizar Estoque" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end" gutter={16}>
          <Col>
            <Button onClick={handleCancel} style={{ marginRight: 8, backgroundColor: 'red', color: 'white' }}>
              Cancelar
            </Button>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit">
              {isEditMode ? "Atualizar" : "Criar"}
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default SellerModalForm;