import React, { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Table, Spin, Pagination, Input, Button, Modal, Switch } from 'antd';
import { format } from 'date-fns';
import { CREATE_PLACE, GET_PLACES, UPDATE_PLACE, UPDATE_PLACE_STATUS } from '../mutations/places';
import PlaceForm from '../components/PlaceForm';

const Places = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isFormModalVisible, setIsFormModalVisible] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [fetchPlaces, { loading, error, data }] = useLazyQuery(GET_PLACES, {
    variables: { page: currentPage, perPage: pageSize, search: searchQuery },
  });

  const [createPlace] = useMutation(CREATE_PLACE);
  const [updatePlace] = useMutation(UPDATE_PLACE);
  const [updatePlaceStatus] = useMutation(UPDATE_PLACE_STATUS);

  useEffect(() => {
    fetchPlaces();
  }, [fetchPlaces]);

  const handleSearch = () => {
    setSearchQuery(searchTerm);
    setCurrentPage(1);
    fetchPlaces({
      variables: {
        page: 1,
        perPage: pageSize,
        search: searchTerm,
      },
    });
  };

  const handleCreatePlace = () => {
    setSelectedPlace(null);
    setIsEditMode(false);
    setIsFormModalVisible(true);
  };

  const handleEditPlace = (place) => {
    setSelectedPlace(place);
    setIsEditMode(true);
    setIsFormModalVisible(true);
  };

  const handleFormCancel = () => {
    setIsFormModalVisible(false);
    setSelectedPlace(null);
    setErrorMessage(null);
  };

  const handleSave = async (values) => {
    setErrorMessage(null);
    try {
      let response;
      if (isEditMode) {
        console.log(values)
        response = await updatePlace({
          variables: {
            id: selectedPlace.id,
            attributes: values,
          }
        });
      } else {
        response = await createPlace({
          variables: {
            attributes: values,
          }
        });
      }

      const errors = response.data?.createPlace?.errors || response.data?.updatePlace?.errors;
      if (errors && errors.length > 0) {
        setErrorMessage(errors.join(', '));
      } else {
        fetchPlaces(); // Refetch Places to update the list
        handleFormCancel(); // Close the modal
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(error.message || 'Ocorreu um erro inesperado.');
    }
  };

  const showConfirmModal = (place) => {
    setSelectedPlace(place);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    setIsModalVisible(false);
    const { id, active } = selectedPlace;
    try {
      await updatePlaceStatus({
        variables: { id: id, active: !active },
        update: (cache, { data: { updatePlaceStatus } }) => {
          const { place } = updatePlaceStatus;
          cache.modify({
            id: cache.identify(place),
            fields: {
              active(cachedActive) {
                return !active;
              }
            }
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  if (loading) return <Spin />;
  if (error) return <p>Error: {error.message}</p>;

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Nome Corporativo',
      dataIndex: 'corporateName',
      key: 'corporateName',
    },
    {
      title: 'Telefone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Criado em',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: (text) => format(new Date(text), 'dd/MM/yyyy - HH:mm'),
    },
    {
      title: 'Atualizado em',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text) => format(new Date(text), 'dd/MM/yyyy HH:mm'),
    },
    {
      title: 'Ativo',
      dataIndex: 'active',
      key: 'active',
      align: 'center',
      render: (active, record) => (
        <Switch
          checked={active}
          onChange={() => showConfirmModal(record)}
        />
      ),
    },
    {
      title: 'Ações',
      key: 'actions',
      render: (text, record) => (
        <Button type="link" onClick={() => handleEditPlace(record)}>Editar</Button>
      ),
    },
  ];

  const places = data?.places?.edges.map(edge => edge.node) || [];
  const { totalCount } = data?.places || { totalCount: 0 };

  return (
    <>
      <div style={{ marginBottom: 16, display: 'flex', alignItems: 'center' }}>
        <Input
          placeholder="Pesquisar"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginRight: 8, width: 300 }}
          onPressEnter={handleSearch}
        />
        <Button type="primary" onClick={handleSearch}>Pesquisar</Button>
        <Button type="primary" onClick={handleCreatePlace} style={{ marginLeft: 'auto' }} >
          Criar Empresa
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={places}
        rowKey="id"
        pagination={false}
      />
      {data?.places?.pageInfo && (
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={totalCount}
          onChange={(page, pageSize) => {
            setCurrentPage(page);
            setPageSize(pageSize);
            fetchPlaces({ variables: { page, perPage: pageSize, search: searchQuery } });
          }}
        />
      )}
      <Modal
        title={isEditMode ? 'Editar Local' : 'Criar Local'}
        open={isFormModalVisible}
        footer={null}
        onCancel={handleFormCancel}
        maskClosable={false}
        getContainer={false}
        width={1200}
      >
        <PlaceForm
          initialValues={selectedPlace || { name: '', corporateName: '', phone: '', email: '', address: {}, logo: null }}
          isEditMode={isEditMode}
          onFinish={handleSave}
          onCancel={handleFormCancel}
          errorMessage={errorMessage}
        />
      </Modal>
      <Modal
        title="Confirmar Alteração"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={() => setIsModalVisible(false)}
        okText="Confirmar"
        cancelText="Cancelar"
      >
        <p>Você realmente deseja {selectedPlace?.active ? 'desativar' : 'ativar'} este local?</p>
      </Modal>
    </>
  );
};

export default Places;
