// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import paymentProfilesReducer from './slices/paymentProfilesSlice'; // Certifique-se de que o caminho esteja correto

export const store = configureStore({
  reducer: {
    paymentProfiles: paymentProfilesReducer,
    // Adicione outros reducers aqui
  },
});

export default store;
