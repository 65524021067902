import { gql, useMutation } from '@apollo/client';

export const GET_CHECKIN_REASONS = gql`
  query GetCheckinReasons($page: Int, $perPage: Int, $search: String) {
    checkinReasons(page: $page, perPage: $perPage, search: $search) {
      edges {
        node {
          id
          description
          active
          createdAt
          updatedAt
        }
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`;
export const CREATE_CHECKIN_REASON = gql`
  mutation CreateCheckinReason($description: String!) {
    createCheckinReason(input: { description: $description }) {
      checkinReason {
        id
        description
        active
      }
      errors
    }
  }
`;

export const UPDATE_CHECKIN_REASON = gql`
  mutation UpdateCheckinReason($id: ID!, $description: String, $active: Boolean) {
    updateCheckinReason(input: {id: $id,  description: $description, active: $active }) {
      checkinReason {
        id
        description
        active
      }
      errors
    }
  }
`;


export const UPDATE_CHECKIN_REASON_STATUS = gql`
  mutation UpdateCheckinReasonStatus($id: ID!, $active: Boolean!) {
    updateCheckinReasonStatus(input: { id: $id, active: $active }) {
      checkinReason {
        id
        updatedAt
        active
      }
      errors
    }
  }
`;

export const useUpdateCheckinReasonStatus = () => {
  return useMutation(UPDATE_CHECKIN_REASON_STATUS);
};
