// src/pages/Dashboard.jsx
import React, { useRef, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Row, Col, Statistic, Divider } from 'antd';
import { SALES_SUMMARY } from '../mutations/dashboard';
import { GET_CUSTOMERS_COUNT } from '../mutations/customers';
import { GET_PRODUCTS_COUNT } from '../mutations/products';
import { GET_PAYMENT_CONDITIONS_COUNT } from '../mutations/paymentConditions';
import { GET_PAYMENT_METHODS_COUNT } from '../mutations/paymentMethods';
import { GET_ORDERS_COUNT } from '../mutations/orders';
import { GET_GROUPS_COUNT } from '../mutations/groups';
import { GET_SELLERS_COUNT } from '../mutations/sellers';
import { fetchPaymentProfilesCount } from '../slices/paymentProfilesSlice';
// import SalesChart from '../components/SalesChart';
import OrderStatusChart from '../components/OrderStatusChart';
import TopSellingProductsChart from '../components/TopSellingProductsChart';
import TopSellingProductsByMonthChart from '../components/TopSellingProductsByMonthChart';
import { formatCurrency } from '../util';
import DashboardTour from '../components/dashboard/DashboardTour';
import { GET_USER_TOUR_STATUS, UPDATE_TOUR_STATUS } from '../mutations/user';
import { GET_PLACES_COUNT } from '../mutations/places';
import { GET_PRICE_SEGMENTATIONS_COUNT } from '../mutations/priceSegmentations';


const Dashboard = () => {
  const { loading, error, data } = useQuery(SALES_SUMMARY, { fetchPolicy: 'network-only' });
  const { data: dataCustomers } = useQuery(GET_CUSTOMERS_COUNT, { fetchPolicy: 'network-only' });
  const { data: dataProducts } = useQuery(GET_PRODUCTS_COUNT, { fetchPolicy: 'network-only' });
  const { data: dataPaymentConditions } = useQuery(GET_PAYMENT_CONDITIONS_COUNT, { fetchPolicy: 'network-only' });
  const { data: dataPaymentMethods } = useQuery(GET_PAYMENT_METHODS_COUNT, { fetchPolicy: 'network-only' });
  const { data: dataSellers } = useQuery(GET_SELLERS_COUNT, { fetchPolicy: 'network-only' });
  const { data: dataOrders } = useQuery(GET_ORDERS_COUNT, { fetchPolicy: 'network-only' });
  const { data: dataGroups } = useQuery(GET_GROUPS_COUNT, { fetchPolicy: 'network-only' });
  const { data: dataPlaces } = useQuery(GET_PLACES_COUNT, { fetchPolicy: 'network-only' });
  const { data: dataPriceSegmentations } = useQuery(GET_PRICE_SEGMENTATIONS_COUNT, { fetchPolicy: 'network-only' });
  const { data: dataUserTourStatus } = useQuery(GET_USER_TOUR_STATUS, { fetchPolicy: 'network-only' });
  const [updateTourStatus] = useMutation(UPDATE_TOUR_STATUS);


  const dispatch = useDispatch();
  const paymentProfilesCount = useSelector((state) => state.paymentProfiles.count);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  const ref8 = useRef(null);
  const [open, setOpen] = useState(false);


  useEffect(() => {
    dispatch(fetchPaymentProfilesCount(false));
  }, [dispatch]);

  useEffect(() => {
    if (dataUserTourStatus && !dataUserTourStatus.userTourStatus) {
      setOpen(true);
    }
  }, [dataUserTourStatus]);

  const handleTourClose = () => {
    setOpen(false);
    updateTourStatus({ variables: { tourCompleted: true } });
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const currentMonth = new Date().getMonth() + 1; // Janeiro é 0
  const currentYear = new Date().getFullYear();


  return (
    <>
      <DashboardTour open={open} onClose={handleTourClose} refs={{ ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8 }} />

      <Divider>Cadastros</Divider>
      <Row gutter={16}>
        <Col span={4}>
          <Card title="Empresas" bordered={false} ref={ref7}>
            <Statistic value={dataPlaces?.placesCount} />
          </Card>
        </Col>
        <Col span={4}>
          <Card title="Clientes" bordered={false} ref={ref3}>
            <Statistic value={dataCustomers?.customersCount} />
          </Card>
        </Col>
        <Col span={4}>
          <Card title="Produtos" bordered={false} ref={ref2}>
            <Statistic value={dataProducts?.productsCount} />
          </Card>
        </Col>
        <Col span={4}>
          <Card title="Formas de pagamento" bordered={false} ref={ref5}>
            <Statistic value={dataPaymentMethods?.paymentMethodsCount} />
          </Card>
        </Col>
        <Col span={4}>
          <Card title="Condições de pagamento" bordered={false} ref={ref6}>
            <Statistic value={dataPaymentConditions?.paymentConditionsCount} />
          </Card>
        </Col>
      </Row>
      <br />
      <Row gutter={16}>
        <Col span={4}>
          <Card title="Grupos" bordered={false} >
            <Statistic value={dataGroups?.groupsCount} />
          </Card>
        </Col>
        <Col span={4}>
          <Card title="Perfis de pagamento" bordered={false} ref={ref4}>
            <Statistic value={paymentProfilesCount} />
          </Card>
        </Col>
        <Col span={4}>
          <Card title="Vendedores" bordered={false} ref={ref1}>
            <Statistic value={dataSellers?.sellersCount} />
          </Card>
        </Col>
        <Col span={4}>
          <Card title="Pedidos realizados" bordered={false}>
            <Statistic value={dataOrders?.ordersCount} />
          </Card>
        </Col>
        <Col span={4}>
          <Card title="Tabelas de preço" bordered={false} ref={ref8}>
            <Statistic value={dataPriceSegmentations?.priceSegmentationsCount} />
          </Card>
        </Col>
      </Row>
      <Divider>Vendas</Divider>
      <Row gutter={16}>
        <Col span={6}>
          <Card title="Total faturado no mês" bordered={false}>
            <Statistic value={formatCurrency(data.salesSummary.totalSalesMonth)} />
          </Card>
        </Col>
        <Col span={6}>
          <Card title="Total vendido no mês" bordered={false}>
            <Statistic value={formatCurrency(data.salesSummary.totalOnMonth)} />
          </Card>
        </Col>
        <Col span={8}>
          {/* <SalesChart data={data.salesSummary.salesByCategory} /> */}
        </Col>
        <Col span={8}>
          {/* <SalesChart data={data.salesSummary.salesBySeller} /> */}
        </Col>
      </Row>
      <br />
      <Row gutter={16}>
        <Col span={12}>
          <Card title="Quantidade de pedidos no mês por status">
            <OrderStatusChart month={currentMonth} year={currentYear} />
          </Card>
        </Col>
      </Row>
      <br />
      <Row gutter={16}>
        <Col span={12}>
          <Card title="Produtos Mais Vendidos no Mês (faturados)">
            <TopSellingProductsByMonthChart month={currentMonth} year={currentYear} />
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Produtos Mais Vendidos (faturados)">
            <TopSellingProductsChart />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
