import { gql } from "@apollo/client";

export const GET_PAYMENT_PROFILES_COUNT = gql`
  query paymentProfilesCount($active: Boolean) {
      paymentProfilesCount(active: $active) 
  }
`;

export const GET_ACTIVE_PAYMENT_PROFILES = gql`
  query GetActivePaymentProfiles {
    activePaymentProfiles {
      id
      name
    }
  }
`;

export const SEARCH_PAYMENT_PROFILES = gql`
  query searchPaymentProfiles($query: String!) {
    searchPaymentProfiles(query: $query) {
      id
      name
      active
    }
  }
`;

export const UPDATE_PAYMENT_PROFILE = gql`
  mutation UpdatePaymentProfile($id: ID!, $name: String!, $active: Boolean!, $profileMethodConditions: [ProfileMethodConditionAttributes!]) {
    updatePaymentProfile(input: { id: $id, name: $name, active: $active, profileMethodConditions: $profileMethodConditions }) {
      paymentProfile {
        id
        name
        active
        profileMethodConditions {
          id
          paymentMethodId
          paymentConditionId
          minimumValue
        }
      }
      errors
    }
  }
`;

export const UPDATE_PAYMENT_PROFILE_STATUS = gql`
  mutation UpdatePaymentProfileStatus($id: ID!, $active: Boolean!) {
    updatePaymentProfileStatus(input: {id: $id, active: $active}) {
      paymentProfile {
        id
        active
      }
      errors
    }
  }
`;

export const CREATE_PAYMENT_PROFILE = gql`
  mutation CreatePaymentProfile($name: String!, $active: Boolean!, $profileMethodConditions: [ProfileMethodConditionAttributes!]) {
    createPaymentProfile(input: { name: $name, active: $active, profileMethodConditions: $profileMethodConditions}) {
      paymentProfile {
        id
        name
        active
        profileMethodConditions {
          paymentMethodId
          paymentConditionId
          minimumValue
        }
      }
      errors
    }
  }
`;

export const GET_PAYMENT_PROFILES = gql`
  query GetPaymentProfiles($page: Int, $perPage: Int, $search: String) {
    paymentProfiles(page: $page, perPage: $perPage, search: $search) {
      edges {
        node {
          id
          name
          active
          profileMethodConditions{
            id
            paymentMethodId
            paymentConditionId
            minimumValue
            createdAt
            updatedAt
            paymentMethod{
              id
              name
            }
            paymentCondition{
              id
              name
              }
            }
          createdAt
          updatedAt
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;