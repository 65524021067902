import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Button, Col, Row, InputNumber, Select, Upload } from 'antd';
import { useCreateProduct, useUpdateProduct } from '../../mutations/products';
import { SEARCH_GROUPS } from '../../mutations/groups';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import './CustomForm.css';
import CurrencyInput from '../CurrencyInput';
import useDebounce from '../../hooks/useDebounce';
import { UploadOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { GET_ACTIVE_PLACES, SEARCH_PLACES } from '../../mutations/places';
import { GET_ACTIVE_PRICE_SEGMENTATIONS, SEARCH_PRICE_SEGMENTATIONS } from '../../mutations/priceSegmentations';

const ProductModalForm = ({ visible, onCancel, initialValues, isEditMode, refetchProducts }) => {
  const [createProduct] = useCreateProduct();
  const [updateProduct] = useUpdateProduct();
  const [searchGroups, { data: groupsData }] = useLazyQuery(SEARCH_GROUPS);
  const [searchPlaces, { data: placesData }] = useLazyQuery(SEARCH_PLACES);
  const [searchPriceSegmentations, { data: priceSegmentationsData }] = useLazyQuery(SEARCH_PRICE_SEGMENTATIONS);
  const [groups, setGroups] = useState([]);
  const [places, setPlaces] = useState([]);
  const [priceSegmentations, setPriceSegmentations] = useState([]);
  const { data } = useQuery(GET_ACTIVE_PLACES);
  const { data: dataPriceSegmentations } = useQuery(GET_ACTIVE_PRICE_SEGMENTATIONS);

  useEffect(() => {
    if (data && data.activePlaces) {
      setPlaces(data.activePlaces);
    }
  }, [data]);

  useEffect(() => {
    if (dataPriceSegmentations && dataPriceSegmentations.activePriceSegmentations) {
      setPriceSegmentations(dataPriceSegmentations.activePriceSegmentations);
    }
  }, [dataPriceSegmentations]);

  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (debouncedSearchTerm.length > 2 || debouncedSearchTerm === '*') {
      searchGroups({ variables: { query: debouncedSearchTerm } });
    }
  }, [debouncedSearchTerm, searchGroups]);

  useEffect(() => {
    if (groupsData) {
      setGroups(groupsData.searchGroups);
    }
  }, [groupsData]);

  useEffect(() => {
    if (placesData) {
      setPlaces(placesData.searchPlaces);
    }
  }, [placesData]);

  useEffect(() => {
    if (priceSegmentationsData) {
      setPriceSegmentations(priceSegmentationsData.searchPriceSegmentations);
    }
  }, [priceSegmentationsData]);

  useEffect(() => {
    if (initialValues) {
      if(initialValues.group){
        setGroups([{id: initialValues.group.id, name: initialValues.group.name}]);
      }
   
      form.setFieldsValue({
        ...initialValues,
        group: initialValues.group?.id,
        infos: initialValues.infos?.map(info => ({
          ...info,
          place: info.place,
          baseUnit: info.base_unit,
          quantityBasicUnities: info.quantity_basic_unities,
        })),
        priceSegmentations: initialValues.priceSegmentations?.map(segmentation => ({
          ...segmentation,
          priceSegmentationId: segmentation.price_segmentation_id.toString(),
          costValue: segmentation.cost_value,
          minimumValue: segmentation.minimum_value,
          value: segmentation.value,
          multiplier: segmentation.multiplier,
        })),
      });

      if (initialValues.imageUrl) {
        setFileList([{ uid: '-1', name: 'image.png', status: 'done', url: initialValues.imageUrl }]);
      }
    }
  }, [initialValues, form, priceSegmentations]);

  const handleSearchGroup = (value) => {
    setSearchTerm(value);
  };

  const handleSearchPlace = (value) => {
    if (value.length > 2 || value === '*') {
      searchPlaces({ variables: { query: value } });
    }
  };

  const handleSearchPriceSegmentation = (value) => {
    if (value.length > 2 || value === '*') {
      searchPriceSegmentations({ variables: { query: value } });
    }
  };

  const handleSubmit = async (values) => {
    
    const formattedValues = {
      ...values,
      infos: values.infos.map(info => ({
        place: info.place,
        baseUnit: info.baseUnit,
        quantityBasicUnities: info.quantityBasicUnities,
        un: info.un,
        stock: (info.stock || '0' ).toString(),
        width: info.width,
        height: info.height,
        weight: info.weight,
      })),
      priceSegmentations: values.priceSegmentations.map(segmentation => ({
        priceSegmentationId: segmentation.priceSegmentationId,
        costValue: segmentation.costValue,
        minimumValue: segmentation.minimumValue,
        value: segmentation.value,
        multiplier: segmentation.multiplier,
        un: segmentation.un,
      })),
      groupId: values.group,
    };

    if (fileList.length > 0 && fileList[0].originFileObj) {
      formattedValues.image = fileList[0].originFileObj;
    }

    try {
      if (isEditMode) {
        await updateProduct({
          variables: { attributes: { id: initialValues.id, ...formattedValues } },
          update: (cache, { data: { updatePedidosMoveisProduct } }) => {
            const { product } = updatePedidosMoveisProduct;
            cache.modify({
              id: cache.identify(product),
              fields: {
                reference() { return product.reference; },
                name() { return product.name; },
                brand() { return product.brand; },
                group() { return product.group; },
                description() { return product.description; },
                imageUrl() { return product.imageUrl; },
              }
            });
          }
        });
      } else {
        await createProduct({
          variables: { attributes: formattedValues },
          update: (cache, { data: { createPedidosMoveisProduct } }) => {
            const { product } = createPedidosMoveisProduct;
            cache.modify({
              fields: {
                products(existingProducts = { edges: [] }) {
                  const newProductRef = cache.writeFragment({
                    data: product,
                    fragment: gql`
                      fragment NewProduct on Product {
                        id
                        reference
                        name
                        brand
                        description
                        group {
                          id
                          name
                        }
                        imageUrl
                      }
                    `
                  });
                  return {
                    ...existingProducts,
                    edges: [...existingProducts.edges, { node: newProductRef }],
                  };
                }
              }
            });
          }
        });
      }
      refetchProducts(); // Refetch products to update the list
      onCancel(); // Fechar o modal após o sucesso
    } catch (error) {
      console.error(error);
      // Manter o modal aberto e exibir o erro se houver
    }
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const handleChange = ({ fileList }) => setFileList(fileList);

  return (
    <Modal
      title={isEditMode ? "Editar Produto" : "Criar Produto"}
      open={visible}
      onCancel={handleCancel}
      footer={null}
      maskClosable={false}
      getContainer={false}
      width={1200}
    >
      <Form
        form={form}
        initialValues={initialValues}
        onFinish={handleSubmit}
        className="custom-form"
        layout="vertical"
        style={{ width: '100%' }}
      >
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item name="reference" label="Referência" rules={[{ required: true, message: 'Por favor insira a referência' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={18}>
            <Form.Item name="name" label="Nome" rules={[{ required: true, message: 'Por favor insira o nome' }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="brand" label="Marca" rules={[{ required: true, message: 'Por favor insira a marca' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="group" label="Grupo">
              <Select
                showSearch
                placeholder="Pesquisar grupo"
                onSearch={handleSearchGroup}
                filterOption={false}
                options={groups.map(group => ({ value: group.id, label: group.name }))}
              />
            </Form.Item>
          </Col>
        </Row>

        <h3>Informações Adicionais</h3>
        <Form.List name="infos">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Row gutter={16} key={key}>
                  <Col span={22}>
                    <Row gutter={16}>
                      <Col span={6}>
                        <Form.Item
                          {...restField}
                          name={[name, 'place']}
                          label="Empresa"
                          rules={[{ required: true, message: 'Empresa é obrigatória' }]}
                        >
                          <Select
                            showSearch
                            placeholder="Pesquisar empresa"
                            onSearch={handleSearchPlace}
                            filterOption={false}
                            options={places.map(place => ({
                              label: `${place.internalId} - ${place.name}`,
                              value: place.internalId,
                            }))}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          {...restField}
                          name={[name, 'un']}
                          label="UN"
                          rules={[{ required: true, message: 'UN é obrigatório' }]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          {...restField}
                          name={[name, 'stock']}
                          label="Estoque"
                          rules={[{ required: true, message: 'Estoque é obrigatório' }]}
                        >
                          <InputNumber
                            style={{ width: '100%' }}
                            min={0}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          {...restField}
                          name={[name, 'baseUnit']}
                          label="Unidade Base"
                          rules={[{ required: true, message: 'Unidade Base é obrigatória' }]}
                        >
                          <Select
                            options={[
                              { label: 'Sim', value: true },
                              { label: 'Não', value: false },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={6}>
                        <Form.Item {...restField} name={[name, 'width']} label="Largura (cm)">
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item {...restField} name={[name, 'height']} label="Altura (cm)">
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item {...restField} name={[name, 'weight']} label="Peso (kg)">
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          {...restField}
                          name={[name, 'quantityBasicUnities']}
                          label="Qtd de Unidades Básicas"
                          rules={[{ required: true, message: 'Qtd de Unidades Básicas é obrigatória' }]}
                        >
                          <InputNumber style={{ width: '100%' }} min={1} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={2} style={{ textAlign: 'center', paddingTop: '57px' }}>
                    <Button
                      type="text"
                      icon={<CloseCircleOutlined style={{ color: 'red' }} />}
                      onClick={() => remove(name)}
                    />
                  </Col>
                </Row>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} style={{ width: '100%', marginBottom: 16 }}>
                  Adicionar Informação
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <h3>Segmentações de Preço</h3>
        <Form.List name="priceSegmentations">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Row gutter={16} key={key}>
                  <Col span={6}>
                    <Form.Item
                      {...restField}
                      name={[name, 'priceSegmentationId']}
                      label="Tabela de Preço"
                      rules={[{ required: true, message: 'Tabela de preço é obrigatória' }]}
                    >
                      <Select
                        showSearch
                        placeholder="Pesquisar Tabela de preço"
                        onSearch={handleSearchPriceSegmentation}
                        filterOption={false}
                        options={priceSegmentations.map(priceSegmentation => ({
                          label: `${priceSegmentation.id} - ${priceSegmentation.name}`,
                          value: priceSegmentation.id,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <Form.Item
                      {...restField}
                      name={[name, 'un']}
                      label="UN"
                      rules={[{ required: true, message: 'UN é obrigatória' }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      {...restField}
                      name={[name, 'costValue']}
                      label="Custo"
                      rules={[{ required: true, message: 'Custo é obrigatório' }]}
                    >
                      <CurrencyInput />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      {...restField}
                      name={[name, 'value']}
                      label="Valor"
                      rules={[{ required: true, message: 'Valor é obrigatório' }]}
                    >
                      <CurrencyInput />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      {...restField}
                      name={[name, 'minimumValue']}
                      label="Valor Mínimo"
                      rules={[{ required: true, message: 'Valor Mínimo é obrigatório' }]}
                    >
                      <CurrencyInput />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      {...restField}
                      name={[name, 'multiplier']}
                      label="Multiplicador"
                      rules={[{ required: true, message: 'Multiplicador é obrigatório' }]}
                    >
                      <InputNumber style={{ width: '100%' }} min={1} />
                    </Form.Item>
                  </Col>
                  <Col span={2} style={{ textAlign: 'center', paddingTop: '25px' }}>
                    <Button
                      type="text"
                      icon={<CloseCircleOutlined style={{ color: 'red' }} />}
                      onClick={() => remove(name)}
                    />
                  </Col>
                </Row>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} style={{ width: '100%', marginBottom: 16 }}>
                  Adicionar Segmentação de Preço
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="description" label="Descrição">
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="image" label="Imagem">
              <Upload
                listType="picture"
                fileList={fileList}
                beforeUpload={() => false} // Prevent automatic upload
                onChange={handleChange}
              >
                <Button icon={<UploadOutlined />}>Selecionar Imagem</Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>

        <Row justify="end" gutter={16}>
          <Col>
            <Button onClick={handleCancel} style={{ marginRight: 8, backgroundColor: 'red', color: 'white' }}>
              Cancelar
            </Button>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit">
              {isEditMode ? "Atualizar" : "Criar"}
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ProductModalForm;
