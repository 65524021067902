import { gql } from '@apollo/client';

export const GET_LINKS = gql`
  query GetLinks($page: Int, $perPage: Int, $search: String) {
    links(page: $page, perPage: $perPage, search: $search) {
      edges {
        node {
          id
          name
          url
          createdAt
          updatedAt
        }
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`;

export const CREATE_LINK = gql`
  mutation CreateLink($name: String!, $url: String!) {
    createLink(input: { name: $name, url: $url }) {
      link {
        id
        name
        url
      }
      errors
    }
  }
`;

export const UPDATE_LINK = gql`
  mutation UpdateLink($id: ID!, $name: String!, $url: String!) {
    updateLink(input: { id: $id, name: $name, url: $url }) {
      link {
        id
        name
        url
      }
      errors
    }
  }
`;
