import React from 'react';
import { useQuery } from '@apollo/client';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { Spin, Alert } from 'antd';
import { GET_TOP_SELLING_PRODUCTS_BY_MONTH } from '../mutations/dashboard';

const TopSellingProductsByMonthChart = ({ month, year }) => {
  const { loading, error, data } = useQuery(GET_TOP_SELLING_PRODUCTS_BY_MONTH, {
    fetchPolicy: 'network-only',
    variables: { month, year },
  });

  if (loading) return <Spin />;
  if (error)
    return (
      <Alert message="Error" type="error" description={error.message} />
    );

  const chartData = data.topSellingProductsByMonth.map((product) => ({
    name: product.name,
    quantidade: product.totalQuantity,
  }));

  return (
    <ResponsiveContainer width="100%" height={500}>
      <BarChart
        layout="vertical"
        data={chartData}
        margin={{
          top: 20,
          right: 0,
          left: 0, // Adjusted for longer labels
          bottom: 20,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" />
        <YAxis
          dataKey="name"
          type="category"
          tick={{
            fontSize: 12,
            fill: '#333',
          }}
          width={180} // Adjusted for longer labels
        />
        <Tooltip />
        <Legend />
        <Bar dataKey="quantidade" fill="#8884d8" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default TopSellingProductsByMonthChart;
