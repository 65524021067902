// src/components/DashboardTour.jsx
import React, { useState } from 'react';
import { Checkbox, Tour, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
const { Title } = Typography;

const DashboardTour = ({ open, onClose, refs }) => {
  const [goToPerson, setGoToPerson] = useState(true);
  const navigate = useNavigate();

  const onFinish = () => {
    if (goToPerson) {
      navigate('/products');
    }
    if (onClose) onClose()
  }

  const onChange = (e) => {
    setGoToPerson(e.target.checked)
  };

  const steps = [
    {
      title: 'Seja bem vindo ao Pedidos Móveis.',
      description: "Antes de começar a usar o aplicativo, alguns dados precisam ser preenchidos e é por ai que devemos começar.",
      nextButtonProps: { children: 'Próximo' },
      target: null,
    },
    {
      title: 'Empresas',
      description: 'Ao se cadastrar você automáticamente cria uma empresa relacionada, e você pode criar mais empresas para poder definir locais de estoque. Essas informações são utilizadas para separar os pedidos por empresa e para poder definir locais de atuação dos vendedores.',
      prevButtonProps: { children: 'Anterior' },
      nextButtonProps: { children: 'Próximo' },
      target: () => refs.ref7.current,
    },
    {
      title: 'Vendedores',
      description: 'Para realizar um pedido no aplicativo, você precisa ao menos um vendedor ativo.',
      prevButtonProps: { children: 'Anterior' },
      nextButtonProps: { children: 'Próximo' },
      target: () => refs.ref1.current,
    },
    {
      title: 'Tabelas de preço',
      description: 'Você pode definir preços diferentes para um mesmo produto utilizando as tabelas de preço. Elas são utilizadas para definir o preço de um produto para um cliente específico. e para serem utilizadas devem ser vinculadas a um cliente que terá acesso a esse preço aplicado na tabela.',
      target: () => refs.ref8.current,
      prevButtonProps: { children: 'Anterior' },
      nextButtonProps: { children: 'Próximo' },
    },
    {
      title: 'Produto',
      description: 'Para realizar um pedido, você vai precisar de ao menos um produto ativo e com preço para utilizar no aplicativo.',
      target: () => refs.ref2.current,
      prevButtonProps: { children: 'Anterior' },
      nextButtonProps: { children: 'Próximo' },
    },
    {
      title: 'Clientes',
      description: 'Você também vai precisar ter cadastrado ao menos um cliente.',
      target: () => refs.ref3.current,
      prevButtonProps: { children: 'Anterior' },
      nextButtonProps: { children: 'Próximo' },
    },
    {
      title: 'Perfil de pagamento',
      description: 'Um cliente para ser utilizado no aplicativo precisa de ao menos um perfil de pagamento criado para ser vinculado a ele.',
      target: () => refs.ref4.current,
      prevButtonProps: { children: 'Anterior' },
      nextButtonProps: { children: 'Próximo' },
    },
    {
      title: 'Forma de pagamento',
      description: 'Um perfil precisa de uma forma de pagamento cadastrada.',
      target: () => refs.ref5.current,
      prevButtonProps: { children: 'Anterior' },
      nextButtonProps: { children: 'Próximo' },
    },
    {
      title: 'Condição de pagamento',
      description: 'E também precisa de uma condição de pagamento cadastrada.',
      target: () => refs.ref6.current,
      prevButtonProps: { children: 'Anterior' },
      nextButtonProps: { children: 'Próximo' },
    },
    {
      title: 'Começar',
      description: 'Comece a preparar o ambiente para utilizar o pedidos móveis.',
      cover: (
        <>
        <Title>Vamos começar?</Title>
          <Checkbox checked={goToPerson} onChange={onChange}>Me mande para o cadastro de Produtos</Checkbox>
        </>
      ),
      prevButtonProps: { children: 'Anterior' },
      nextButtonProps: { children: 'Concluir' },
    },
  ];

  return <Tour open={open} onClose={onFinish} steps={steps} />;
};

export default DashboardTour;
